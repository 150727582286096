import { gql } from 'apollo-boost';


export const GET_CATEGORIES = gql`
    query {
        categories {
            id
            name
            uid
            order
            description
        }
    } 
`;


export const GET_SALES_BY_PERIOD = gql`
    query getSalesByPeriod($startDate: String!, $endDate: String!) {
        salesByPeroid(startDate: $startDate, endDate: $endDate) {
            uid
            dayoff
            createdAt
            salePrice {
                total
                credit
                cash
                prepaid
                here
                togo
                woman
                man
                couple
                group
            }
            saleCount {
                total
                credit
                cash
                prepaid
                here
                togo
                woman
                man
                couple
                group
            }
            cashReceiptsCount
            categoriesSales {
                categoryID
                count
                price
            }
        }
    } 
`;



export const CLOSING_SALE = gql`
    mutation SaleClosingByDate($date: String!) {
        saleClosingByDate(date: $date) {
            uid
        }
    }
`;


export const UPDATE_DAYOFF = gql`
    mutation UpdateDayoff($date: String!, $value: Boolean!) {
        updateDayoff(date: $date, value: $value) {
            uid,
            dayoff
        }
    }
`;

