import React from 'react';
import Products from './Products';
import * as queryString from 'query-string';
import { SegmentedControlsByCategory } from '../../components';


class ProductContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            params: {
                sale: true,
                category: 'all',
            }
        }
    }

    componentDidMount = () => {
        document.body.className = '';
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let parameter = queryString.parse(nextProps.location.search);
        parameter.sale = parameter.sale !== undefined ? JSON.parse(parameter.sale) : true;
        parameter.category = parameter.category !== undefined ? parameter.category : 'all';
        
        if( parameter.sale !== prevState.sale || parameter.category !== prevState.category ) {
            return {
                params: {
                    sale: parameter.sale,
                    category: parameter.category,
                }
            }
        }

        return null;
    }


    handleSale = (e) => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('sale', e.target.value === 'true');
        currentUrlParams.set('category', this.state.params.category);

        this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());
    }

    handleCategory = (e) => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('sale', this.state.params.sale);
        currentUrlParams.set('category', e.target.value);

        this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());
    }

    render() {
        const { params } = this.state;

        return(
            <>
                <article className="layout-contents">
                    <div className="layout-content-utils">
                        <div className="content-view">
                            <h1 className="page-title">상품 관리 - 목록</h1>
                        </div>

                        <div className="utils-view">
                            <fieldset className="utils-filter">
                                <legend>filter</legend>
                                
                                <div className="segmented-control">
                                    <input type="radio" id="sale" name="sale" value={true} checked={params.sale === true} onChange={this.handleSale} />
                                    <label htmlFor="sale">판매중</label>

                                    <input type="radio" id="not-sale" name="sale" value={false} checked={params.sale === false} onChange={this.handleSale} />
                                    <label htmlFor="not-sale">판매종료</label>
                                </div>

                                <SegmentedControlsByCategory filter={{ category: params.category }} onChange={this.handleCategory} />
{/*}
                                <select name="" id="" onChange={this.handleCategory} value={params.category}>
                                    <option value="all">모두</option>
                                    <Query query={GET_CATEGORIES}>
                                        {({ loading, data, error }) => {
                                            if( loading ) return <option>로딩중...</option>
                                            if( error )  return <option>{error.description}</option>

                                            const optionsCategories = data.categories.map((category) => {
                                                return(
                                                    <option key={category.id} value={category.id}>{category.name}</option>
                                                );
                                            });

                                            return(
                                                <>
                                                    {optionsCategories}
                                                </>
                                                
                                            );
                                        }}
                                    </Query>
                                    </select>
                                    */}
                            </fieldset>
                        </div>
                    </div>

                    <div className="layout-content-inner">
                        
                        <Products {...this.props} params={this.state.params} />

                    </div>
                </article>
            </>
        );
    }
}

export default ProductContainer;
