import { NUMBERREG } from '../../constants';

const validate = values => {
    const errors = {};

    if( !values.name ) {
        errors.name = "필수 항목입니다.";
    }

    if( !values.category ) {
        errors.category = "필수 항목입니다.";
    }

    if( !values.price ) {
        errors.price = "필수 항목입니다.";
    } else if( !values.price.toString().match(NUMBERREG) ) {
        errors.price = "제대로된 금액을 입력해주세요.";
    }

    if( values.hot.available ) {
        if( !values.hot.difference ) {
            errors.hot = {
                difference: "필수 항목입니다."
            }
        } else if( !values.hot.difference.toString().match(NUMBERREG) ) {
            errors.hot = {
                difference: "제대로된 금액을 입력해주세요."
            }
        }
    }

    if( values.iced.available ) {
        if( !values.iced.difference ) {
            errors.iced = {
                difference: "필수 항목입니다."
            }
        } else if( !values.iced.difference.toString().match(NUMBERREG) ) {
            errors.iced = {
                difference: "제대로된 금액을 입력해주세요."
            }
        }
    }

    return errors;
}

export default validate;
