import React from 'react';
import { Query } from 'react-apollo';
import { GET_CATEGORIES } from './queries';
import { LoadingComponent } from '../components';


const SegmentedControlsByCategory = ({filter, onChange}) => {
    return(
        <Query query={GET_CATEGORIES}>
            {({ loading, data, error }) => {
                if( loading ) return <div className="segmented-control"><LoadingComponent size="inline" /></div>
                if( error )  return <div className="segmented-control">{error.description}</div>

                const scCategories = data.categories.map((category, index) => {
                    const { id, name } = category;
                    return(
                        <React.Fragment key={index}>
                            <input type="radio" id={id} name="category" value={id} checked={filter.category === id} onChange={(e) => {onChange(e)}} />
                            <label htmlFor={id}>{name}</label>
                        </React.Fragment>
                    );
                });

                scCategories.unshift(
                    <React.Fragment key={'all'}>
                        <input type="radio" id="all" name="category" value="all" checked={filter.category === 'all'} onChange={(e) => {onChange(e)}} />
                        <label htmlFor="all">ALL</label>
                    </React.Fragment>
                );

                return(
                    <div className="segmented-control">
                        {scCategories}
                    </div>
                );
            }}
        </Query>
    );
};

export default SegmentedControlsByCategory;