import React from 'react';
import { GlobalMenu } from '../components';

class OrderLayout extends React.PureComponent {
  render() {
    return (
        <>
            <GlobalMenu {...this.props.children.props} />
            <main role="main">
                <div className="layout-contents-container layout-with-order">
                    {this.props.children}
                </div>
            </main>
        </>
    );
  }
}

export default OrderLayout;
