import { gql } from 'apollo-boost';

export const GET_SALES_BY_PERIOD = gql`
    query getSalesByPeriod($period: String!, $startDate: String!, $endDate: String!) {
        salesByPeroid(period: $period, startDate: $startDate, endDate: $endDate) {
            orderDay
            price
            paymentType
            takeout
            createdAt
            items {
                categoryID
                productID
                name
                offer
                count
            }
        }
    } 
`;
