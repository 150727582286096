import { gql } from 'apollo-boost';

export const GET_PRODUCTS = gql`
    query getProducts($sale: Boolean, $category: String) {
        products(sale: $sale, category: $category) {
            id
            category {
                id
                name
                uid
            }
            name
            price
            hot {
                available
                difference
            }
            iced {
                available
                difference
            }
            sale
            takeout
            order
            description
        }
    } 
`;

export const GET_PRODUCT = gql`
    query getProduct($id: String!) {
        product(id: $id) {
            id
            category {
                id
                name
            }
            name
            price
            hot {
                available
                difference
            }
            iced {
                available
                difference
            }
            sale
            takeout
            order
            description
        }
    } 
`;

export const ADD_PRODUCT = gql`
    mutation AddProduct($category: String!, $name: String!, $price: Int!, $hot: OfferInput!, $iced: OfferInput!, $sale: Boolean!, $takeout: Boolean!, $description: String) {
        addProduct(category: $category, name: $name, price: $price, hot: $hot, iced: $iced, sale: $sale, takeout: $takeout, description: $description) {
            id
            category {
                id
                name
            }
            name
            price
            hot {
                available
                difference
            }
            iced {
                available
                difference
            }
            sale
            takeout
            description
        }
    }
`;

export const UPDATE_PRODUCT = gql`
    mutation UpdateProduct($id: String!, $category: String!, $name: String!, $price: Int!, $hot: OfferInput!, $iced: OfferInput!, $sale: Boolean!, $takeout: Boolean!, $order: Int!, $description: String) {
        updateProduct(id: $id, category: $category, name: $name, price: $price, hot: $hot, iced: $iced, sale: $sale, takeout: $takeout, order: $order, description: $description) {
            id
            category {
                id
                name
            }
            name
            price
            hot {
                available
                difference
            }
            iced {
                available
                difference
            }
            sale
            takeout
            order
            description
        }
    }
`;

export const DELELTE_PRODUCT = gql`
    mutation DeleteProduct($id: String!) {
        deleteProduct(id: $id) {
            id
            category {
                id
            }
            sale
        }
    }
`;


export const GET_CATEGORIES = gql`
    query {
        categories {
            id
            name
            uid
            order
            description
        }
    } 
`;
