import React from 'react';

import Orders from './Orders';
import $ from 'jquery';
import moment from 'moment';
import 'daterangepicker';

import * as queryString from 'query-string';

class OrdersContainer extends React.Component {
    constructor(props) {
        super(props);

        let parameter = queryString.parse(props.location.search);

        this.state = {
            params: {
                startDate: parameter.startDate !== undefined ? parameter.startDate : moment().format("YYYY-MM-DD"),
                endDate: parameter.endDate !== undefined ? parameter.endDate : moment().format("YYYY-MM-DD")
            }
        }
    }

    setDateRangePicker = (startDate, endDate) => {
        let _ = this;
        if( $('input[name="dates"]').data('daterangepicker') !== undefined ) {
            $('input[name="dates"]').data('daterangepicker').remove();
        }
        $('input[name="dates"]').daterangepicker({
            showISOWeekNumbers: true,
            startDate: startDate,
            endDate: endDate,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            },            
            locale: {
                format: 'YYYY-MM-DD'
            }
        }, function(start, end) {
            _.handleDates(start, end);
        });
    }

    componentDidMount = () => {
        document.body.className = '';

        this.setDateRangePicker(this.state.params.startDate, this.state.params.endDate);
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        this.setDateRangePicker(nextState.params.startDate, nextState.params.endDate);
        return true;
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let parameter = queryString.parse(nextProps.location.search);
        parameter.startDate = parameter.startDate !== undefined ? parameter.startDate : moment().format("YYYY-MM-DD");
        parameter.endDate = parameter.endDate !== undefined ? parameter.endDate : moment().format("YYYY-MM-DD");
        
        if( parameter.startDate !== prevState.startDate || parameter.endDate !== prevState.endDate ) {
            return {
                params: {
                    startDate: parameter.startDate,
                    endDate: parameter.endDate,
                }
            }
        }

        return null;
    }

    handleDates = (start, end) => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('startDate', start.format('YYYY-MM-DD'));
        currentUrlParams.set('endDate', end.format('YYYY-MM-DD'));

        this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());
    }

    render() {
        return(
            <>
            <article className="layout-contents">
                <div className="layout-content-utils">
                    <div className="content-view">
                        <h1 className="page-title">주문 관리 - 목록</h1>
                    </div>

                    <div className="utils-view">
                        <fieldset className="utils-filter">
                            <legend></legend>

                            <input type="text" id="" name="dates" />
                        </fieldset>
                    </div>
                </div>

                <div className="layout-content-inner">

                    <Orders {...this.props} params={this.state.params} />

                </div>
            </article>
            </>
        )
    }
}


export default OrdersContainer;