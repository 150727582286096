import React from 'react';
import { Query, Mutation } from 'react-apollo';
import { GET_PRODUCTS, GET_PRODUCT, UPDATE_PRODUCT, DELELTE_PRODUCT } from './queries';

import { Form, Field } from 'react-final-form'
import validates from './validates';
import { LoadingComponent, OptionsByCategory } from '../../components';
import { toast } from "react-toastify";

import $ from 'jquery';
import 'jquery-confirm';


const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
);

class UpdateProductContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: props.match.params.id,
        }
    }

    render() {
        const { id } = this.state;

        return(
            <article className="layout-contents">
                <div className="layout-content-utils">
                    <div className="content-view">
                        <h1 className="page-title">상품 관리 - 수정</h1>
                    </div>
                </div>

                <div className="layout-content-inner">

                <Query
                    query={GET_PRODUCT}
                    variables={{ id: id }}
                    refetchQueries={[{ query: GET_PRODUCTS }]}
                >
                    {({ loading, data, error }) => {
                        if( loading ) return <LoadingComponent />
                        if( error )  return <div>{error.description}</div>

                        const product = data.product;
                        product.price = data.product.price.toString();
                        product.hot.difference = data.product.hot.difference.toString();
                        product.iced.difference = data.product.hot.difference.toString();

                        let productDetail = (
                            <>
                            <Mutation
                                mutation={UPDATE_PRODUCT}
                                onCompleted={() => {
                                    toast.success("상품 수정이 완료되었습니다. 목록페이지로 이동합니다.");
                                    this.props.history.push('/products');
                                }}
                                update={( cache, { data: { updateProduct } }) => {
                                    cache.writeQuery({
                                        query: GET_PRODUCT,
                                        variables: {
                                            id: updateProduct.id
                                        },
                                        data: {
                                            product: updateProduct
                                        }
                                    });
                                }}
                            >
                                {( updateProduct, result ) => {
                                    const { data, loading, error, called } = result;

                                    return(
                                        <Form
                                            onSubmit={(values) => {
                                                updateProduct({
                                                    variables: {
                                                        id: values.id,
                                                        category: values.category.id,
                                                        name: values.name,
                                                        price: parseInt(values.price, 10),
                                                        hot: {
                                                            available: values.hot.available,
                                                            difference: parseInt(values.hot.difference, 10)
                                                        },
                                                        iced: {
                                                            available: values.iced.available,
                                                            difference: parseInt(values.iced.difference, 10)
                                                        },
                                                        sale: values.sale,
                                                        takeout: values.takeout,
                                                        order: parseInt(values.order, 10),
                                                        description: !values.description ? "" : values.description
                                                    }
                                                });
                                            }}
                                            initialValues={product}
                                            validate={validates}
                                            render={({ handleSubmit, submitting, pristine, invalid, values, variables, validate, meta }) => (
                                                <form onSubmit={handleSubmit}>
                                                    <fieldset className="forms">
                                                        <legend>상품 상세</legend>
                    
                                                        <div className="form-group">
                                                            <label htmlFor="id" className="label-with-input">id</label>
                                                            <Field component="input" type="text" name="id" readOnly />
                                                        </div>
                    
                                                        <Field name="name">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor={input.name} className="label-with-input">상품명</label>
                                                                    <input {...input} type="text" id={input.name} placeholder="상품명을 입력하세요." data-invalid={meta.touched && meta.invalid} />
                                                                    {meta.error && meta.touched && <p className="validation-message">{meta.error}</p>}
                                                                </div>
                                                            )}
                                                        </Field>
                    
                                                        <Field name="sale" type="checkbox">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor={input.name} className="label-with-input">판매여부</label>
                                                                    
                                                                    <input {...input} type="checkbox" id={input.name} checked={input.value === true} data-invalid={meta.touched && meta.invalid} />
                                                                    <label htmlFor={input.name}>판매중</label>
                                                                    {meta.error && meta.touched && <p className="validation-message">{meta.error}</p>}
                                                                </div>
                                                            )}
                                                        </Field>
                    
                                                        <Field name="category.id">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor={input.name} className="label-with-input">카테고리</label>
                                                                    
                                                                    <select {...input} id={input.name} data-invalid={meta.touched && meta.invalid} value={input.value}>
                                                                        <option value="">카테고리를 선택하세요.</option>
                                                                        <OptionsByCategory/>
                                                                    </select>
                                                                    {meta.error && meta.touched && <p className="validation-message">{meta.error}</p>}
                                                                </div>
                                                            )}
                                                        </Field>
                    
                                                        <Field name="price">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor={input.name} className="label-with-input">기준가격(원)</label>
                    
                                                                    <input {...input} type="number" id={input.name} step="500" placeholder="기준가격을 입력하세요." onFocus={(e) => {e.target.select()}} data-invalid={meta.touched && meta.invalid} />
                                                                    {meta.error && meta.touched && <p className="validation-message">{meta.error}</p>}
                                                                </div>
                                                            )}
                                                        </Field>
                    
                    
                                                        <div className="form-group">
                                                            <label htmlFor="offer" className="label-with-input">제공</label>
                    
                                                            <div className="form-item">
                                                                <Field name="hot.available" type="checkbox">
                                                                    {({ input, meta }) => (
                                                                        <>
                                                                            <input {...input} type="checkbox" id={input.name} checked={input.value === true} />
                                                                            <label htmlFor={input.name}>뜨거운 음료(HOT)</label>
                    
                                                                            <Condition when={input.name} is={true}>
                                                                                <Field name="hot.difference">
                                                                                    {({ input, meta }) => (
                                                                                        <>
                                                                                        <div className="form-item row">
                                                                                            <label htmlFor={input.name} className="label-with-input">가격차이</label>
                                                                                            <input {...input} type="number" id={input.name} step="500" placeholder="가격차이" onFocus={(e) => {e.target.select()}} data-invalid={meta.touched && meta.invalid} />
                                                                                            {meta.error && meta.touched && <p className="validation-message">{meta.error}</p>}
                                                                                        </div>
                                                                                        </>
                                                                                    )}
                                                                                </Field>
                                                                            </Condition>
                                                                        </>
                                                                    )}
                                                                </Field>
                                                            </div>
                    
                                                            <div className="form-item">
                                                                <Field name="iced.available" type="checkbox">
                                                                    {({ input, meta }) => (
                                                                        <>
                                                                            <input {...input} type="checkbox" id={input.name} checked={input.value === true} />
                                                                            <label htmlFor={input.name}>차가운 음료(ICED)</label>
                    
                                                                            <Condition when={input.name} is={true}>
                                                                                <Field name="iced.difference">
                                                                                    {({ input, meta }) => (
                                                                                        <>
                                                                                        <div className="form-item row">
                                                                                            <label htmlFor={input.name} className="label-with-input">가격차이</label>
                                                                                            <input {...input} type="number" id={input.name} step="500" placeholder="가격차이" onFocus={(e) => {e.target.select()}} data-invalid={meta.touched && meta.invalid} />
                                                                                            {meta.error && meta.touched && <p className="validation-message">{meta.error}</p>}
                                                                                        </div>
                                                                                        </>
                                                                                    )}
                                                                                </Field>
                                                                            </Condition>
                                                                        </>
                                                                    )}
                                                                </Field>
                                                            </div>
                                                        </div>
                    
                                                        <Field name="takeout">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor={input.name} className="label-with-input">테이크아웃</label>
                    
                                                                    <input {...input} type="checkbox" id={input.name} checked={input.value === true} />
                                                                    <label htmlFor={input.name}>가능</label>
                                                                </div>
                                                            )}
                                                        </Field>
                    
                                                        <Field name="order">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor={input.name} className="label-with-input">상품 순서</label>
                                                                    <input {...input} type="number" id={input.name} step="1" placeholder="순서를 입력하세요." data-invalid={meta.touched && meta.invalid} />
                                                                    {meta.error && meta.touched && <p className="validation-message">{meta.error}</p>}
                                                                </div>
                                                            )}
                                                        </Field>
                    
                                                        <Field name="description">
                                                            {({ input, meta }) => (
                                                                <div className="form-group">
                                                                    <label htmlFor={input.name} className="label-with-input">설명</label>
                    
                                                                    <textarea {...input} id={input.name} cols="10" rows="30" placeholder="설명을 입력하세요."></textarea>
                                                                </div>
                                                            )}
                                                        </Field>
                    
                    
                                                        <div className="form-group-submit">
                                                            <button type="button" className="button-normal" onClick={() => {this.props.history.goBack()}}><span className="btn-label">취소</span></button>
                                                            <Mutation
                                                                mutation={DELELTE_PRODUCT}
                                                                variables={{
                                                                    id: this.state.id
                                                                }}
                                                                onCompleted={() => {
                                                                    toast.success("상품 삭제가 완료되었습니다. 목록페이지로 이동합니다.");
                                                                    this.props.history.push('/products');
                                                                }}
                                                                refetchQueries={[{ query: GET_PRODUCTS }]}
                                                            >
                                                                {( deleteProduct, result ) => {
                                                                    const { data, loading, error, called } = result;
                                                                    return(
                                                                        <button type="button" className="button-submit" data-type="strong" disabled={submitting || loading} data-state={loading && "loading"}
                                                                            onClick={() => {
                                                                                $.confirm({
                                                                                    title: 'Warning!',
                                                                                    content: '삭제하시겠습니까?',
                                                                                    type: 'warning',
                                                                                    buttons: {
                                                                                        cancel: {
                                                                                            text: 'CANCEL',
                                                                                            btnClass: 'button-normal',
                                                                                            action: function() {
                                                                                                console.log("cancel");
                                                                                            }
                                                                                        },
                                                                                        confirm: {
                                                                                            text: 'OK',
                                                                                            btnClass: 'button-submit strong',
                                                                                            action: function() {
                                                                                                deleteProduct();
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                })
                                                                            }} 
                                                                        ><span className="btn-label">삭제</span></button>
                                                                    )
                                                                }}
                                                            </Mutation>
                                                            <button type="submit" className="button-submit" disabled={submitting || pristine || invalid || loading} data-state={loading && "loading"}><span className="btn-label">수정</span></button>
                                                        </div>
                                                    </fieldset>
                                                </form>
                                            )}
                                        />
                                    )
                                }}
                            </Mutation>
                            </>
                        );
                        return(
                            <div>{productDetail}</div>
                        )
                    }}
                </Query>
                </div>
            </article>
        );
    }
}

export default UpdateProductContainer;