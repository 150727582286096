import React from 'react';
import { GlobalMenu, GlobalNavigation } from '../components';

class SideMenuLayout extends React.PureComponent {
  render() {
    return (
        <>
            <GlobalMenu {...this.props.children.props} />
            <main role="main">
                <div className="layout-contents-container layout-with-sidemenu">
                    <GlobalNavigation {...this.props.children.props} />

                    {this.props.children}
                </div>
            </main>
        </>
    );
  }
}

export default SideMenuLayout;
