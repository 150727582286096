import React from 'react';
import { SaleChartByTime } from '../../components';


import $ from 'jquery';
import moment from 'moment';
import 'daterangepicker';

import * as queryString from 'query-string';


class SalesByTimeContainer extends React.Component {
    constructor(props) {
        super(props);

        let parameter = queryString.parse(props.location.search);

        this.state = {
            params: {
                date: parameter.date !== undefined ? parameter.date : moment().format("YYYY-MM-DD")
            }
        }
    }

    setDatePicker = (date) => {
        let _ = this;
        if( $('input[name="dates"]').data('daterangepicker') !== undefined ) {
            $('input[name="dates"]').data('daterangepicker').remove();
        }

        $('input[name="dates"]').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            showISOWeekNumbers: true,
            startDate: date,
            minDate: moment(new Date("2018-12-01")),
            maxDate: moment(new Date()),
            locale: {
                format: 'YYYY-MM-DD',
            }
        }, function(start) {
            _.handleDates(start);
        });
        
    }

    componentDidMount = () => {
        document.body.className = '';

        this.setDatePicker(this.state.params.date);
    }


    shouldComponentUpdate = (nextProps, nextState) => {
        this.setDatePicker(nextState.params.date);
        return true;
    }


    static getDerivedStateFromProps = (nextProps, prevState) => {
        let parameter = queryString.parse(nextProps.location.search);
        parameter.date = parameter.date !== undefined ? parameter.date : moment(new Date()).format("YYYY-MM-DD");
        
        if( parameter.date !== prevState.date ) {
            return {
                params: {
                    date: parameter.date
                }
            }
        }

        return null;
    }



    handleDates = (date) => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('date', date.format('YYYY-MM-DD'));

        this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());
    }

    render() {
        const { date } = this.state.params;

        return(
            <>
            <article className="layout-contents">
                <div className="layout-content-utils">
                    <div className="content-view">
                        <h1 className="page-title">시간대별 주문건수 및 매출추이</h1>
                    </div>

                    <div className="utils-view">
                        <fieldset className="utils-filter">
                            <legend></legend>

                            <input type="text" id="" name="dates" />
                        </fieldset>
                    </div>
                </div>

                <div className="layout-content-inner">
                    
                    <SaleChartByTime date={date} />

                </div>
            </article>
            </>
        );
    }
}


export default SalesByTimeContainer;