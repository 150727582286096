import React from 'react';
import { withApollo } from 'react-apollo';
import { SegmentedControlsByCategory } from '../../components';
import { OFFER } from '../../constants';



class FilterComponent extends React.Component {
    state = {
        filter: {
            offer: OFFER.hot,
            category: 'all'
        }
    }

    componentDidMount = () => {
    }

    handleUpdateOffer = (e) => {
        const { filter } = this.state;
        const { onChange } = this.props;

        const newFilter = {
            ...filter,
            offer: parseInt(e.target.value, 10)
        }

        this.setState({
            filter: newFilter
        });

        onChange(newFilter);
    }

    handleUpdateCategory = (e) => {
        const { filter } = this.state;
        const { onChange } = this.props;

        const newFilter = {
            ...filter,
            category: e.target.value
        }
        this.setState({
            filter: newFilter
        });

        onChange(newFilter);
    }


    render() {
        const { filter } = this.state;
        
        return(
            <>
            <form ref={form => this.form = form}>
                <fieldset className="utils-filter">
                    <legend>filter</legend>

                    <div className="segmented-control">
                        <input type="radio" id="hot" name="offer" value={OFFER.hot} checked={filter.offer === OFFER.hot} onChange={this.handleUpdateOffer} />
                        <label htmlFor="hot">HOT</label>

                        <input type="radio" id="iced" name="offer" value={OFFER.iced} checked={filter.offer === OFFER.iced} onChange={this.handleUpdateOffer} />
                        <label htmlFor="iced">ICED</label>
                    </div>

                    <SegmentedControlsByCategory filter={filter} onChange={this.handleUpdateCategory} />
                </fieldset>
            </form>
            </>
        )
    }
}

export default withApollo(FilterComponent);
