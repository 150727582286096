import React from 'react';
import LoginForm from './LoginForm'

class LoginContainer extends React.Component {
    componentDidMount() {
        document.body.className = 'page-login';
    }

    _handleSubmit = () => {
        this.props.history.push('/');
    }

    render() {
        return(
            <LoginForm onSubmit={this._handleSubmit} />
        );
    }
}

export default LoginContainer;
