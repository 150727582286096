import { PHONENUMBERREG, PAYMENTTYPE } from '../../constants';

const validate = values => {
    const errors = {};

    if( !values.items || values.items.length === 0 ) {
        errors.items = ["필수 항목입니다."];
    }

/*
    if( Number(values.paymentType) === PAYMENTTYPE.prepaid ) {
        if( values.cellPhone === undefined || values.cellPhone.length === 0 ) {
            errors.cellPhone = "선결제 선택시, 휴대폰 번호는 필수 항목입니다.";
        }        
    }
*/

    if( values.cellPhone !== undefined ) {
        if( values.cellPhone.length !== 0 && !values.cellPhone.match(PHONENUMBERREG) ) {
            errors.cellPhone = "휴대폰 번호를 입력해주세요.";
        }
    }

    return errors;
}

export default validate;
