import React from 'react';
import { NavLink } from 'react-router-dom';

class GlobalNavigation extends React.Component {
    handleClickMenus = () => {
        document.querySelector('.layout-wrap').removeAttribute("data-menu-expand");
    }

    render() {
        return(
            <nav className="layout-sidemenu-container">
                <div className="layout-sidemenu-header">
                    <h2 className="hidden">navigation menus</h2>
                </div>

                <h3 className="menus">관리</h3>
                <ul className="list-menus">
                    <li><a href="#" onClick={this.handleClickMenus}>카테고리 관리</a></li>
                    <li><NavLink to="/products" activeClassName="selected" onClick={this.handleClickMenus}>상품 관리</NavLink></li>
                    <li><NavLink to="/orders" activeClassName="selected" onClick={this.handleClickMenus}>주문 관리</NavLink></li>
                </ul>

                <h3 className="menus">정산</h3>
                <ul className="list-menus">
                    <li><NavLink exact to="/revenue" activeClassName="selected" onClick={this.handleClickMenus}>기간별 정산내역</NavLink></li>
                    <li><NavLink exact to="/revenue/saleByTime" activeClassName="selected" onClick={this.handleClickMenus}>시간대별 주문건수 및 매출추이</NavLink></li>
                    {/* <li><NavLink to="/statistics/sales" activeClassName="selected" onClick={this.handleClickMenus}>[beta] 기간별 매출</NavLink></li> */}
                </ul>
            </nav>
        );
    }
}

export default GlobalNavigation;
