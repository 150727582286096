import React from 'react';
import { Mutation } from 'react-apollo';
import { SIGNIN } from './queries';
import { Form, Field } from 'react-final-form'
import validates from './validates';
import { isDev, AUTH_TOKEN } from '../../constants'
import { removeToken } from '../../authroute';
import { toast } from "react-toastify";



class LoginForm extends React.Component {
    render() {
        return(
            <Mutation
                mutation={SIGNIN}
                onCompleted={(data) => {
                    toast.success("로그인에 성공했습니다.");
                    localStorage.setItem(AUTH_TOKEN, data.login.token);
                    this.props.onSubmit();
                }}
            >
                {( login, result ) => {
                    const { data, loading, error, called } = result;
                    
                    return(
                        <Form
                            onSubmit={(values) => {
                                login({
                                    variables: {
                                        email: values.email,
                                        password: values.password
                                    }
                                });
                            }}
                            validate={validates}
                            render={({ handleSubmit, submitting, pristine, invalid, values, variables, validate, meta }) => (
                                <form onSubmit={handleSubmit}>
                                    <span className="logo">cafe modern B</span>
                                    <h1 className="login-title">{isDev ? "DEV DB " : ""}Login</h1>

                                    <fieldset className="forms login-forms">
                                        <legend>login forms</legend>

                                        <Field name="email">
                                            {({ input, meta }) => (
                                                <div className="form-item">
                                                    <label htmlFor="email" className="label-with-input">email</label>
                                                    <input {...input} type="email" id="email" placeholder="email here" data-invalid={meta.touched && meta.invalid} />
                                                    {meta.error && meta.touched && <p className="validation-message">{meta.error}</p>}
                                                </div>
                                            )}
                                        </Field>

                                        <Field name="password">
                                            {({ input, meta }) => (
                                                <div className="form-item">
                                                    <label htmlFor="pwd" className="label-with-input">password</label>
                                                    <input {...input} type="password" id="pwd" placeholder="password here" data-invalid={meta.touched && meta.invalid} />
                                                    {meta.error && meta.touched && <p className="validation-message">{meta.error}</p>}
                                                </div>
                                            )}
                                        </Field>

                                        {error && (
                                            <>
                                            <p className="validation-message">{error.message}</p>
                                            <button type="button" className="button-submit" data-type="strong" data-size="sm" onClick={removeToken()}>logout</button>
                                            </>
                                        )}

                                        <div className="form-submit">
                                            <button type="submit" className="button-submit" disabled={submitting || pristine || invalid || loading} data-state={loading && "loading"}><span className="btn-label">login</span></button>
                                        </div>
                                    </fieldset>
                                </form>      
                            )}
                        />
                        )
                    }}
                </Mutation>
            );
    }
}



export default LoginForm;
