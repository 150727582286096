import React from 'react';
import { Link } from 'react-router-dom';
import { Query, withApollo } from 'react-apollo';
import { GET_PRODUCTS, GET_CATEGORIES } from './queries';
import { LoadingComponent } from '../../components'
import { numberWithCommas } from '../../constants';


class Products extends React.Component {
    state = {
        categories: null
    }

    componentDidMount = () => {
    }

    render() {
        const { params } = this.props;
        
        return(
            <>
            <div className="tbl-submit">
                <Link to="/products/add" className="button-submit">상품추가</Link>
            </div>

            <table className="tbl-data">
                <caption>상품관리</caption>
                <thead>
                    <tr>
                        <th className="txt-l">판매중</th>
                        <th className="txt-l">카테고리</th>
                        <th className="txt-l">상품명</th>
                        <th className="txt-r">기준가격</th>
                        <th className="txt-c">테이크아웃</th>
                    </tr>
                </thead>
                <tbody>

                    <Query query={GET_PRODUCTS}
                        variables={{
                            category: params.category,
                            sale: params.sale
                        }}
                        fetchPolicy={"cache-and-network"}
                    >
                        {({ loading, data, error }) => {
                            if( loading ) return <tr><td colSpan="5" className="none"><LoadingComponent /></td></tr>
                            if( error )  return <tr><td colSpan="5" className="none">error {error.message}</td></tr>

                            let listProducts = data.products.map((product) => {

                                const { id, sale, category, name, price, takeout, order } = product;
                                let url = `/products/edit/${id}`;
                                return(
                                    <tr key={id} data-category={category.uid} data-id={id}>
                                        <td data-label="판매중">{sale ? "판매중" : "판매종료"}</td>
                                        <td data-label="카테고리">{category.name}</td>
                                        <th data-label="상품명"><Link to={url}>[{order}]{name}</Link></th>
                                        <td data-label="기준가격" className="txt-r">{numberWithCommas(price)}</td>
                                        <td data-label="테이크아웃" className="txt-c">{takeout ? "가능" : "불가능"}</td>
                                    </tr>
                                );
                            });

                            if( data.products.length === 0 ) {
                                listProducts = <tr><td colSpan="5" className="none">등록된 상품이 없습니다.</td></tr>
                            }

                            return(
                                <>
                                {listProducts}
                                </>
                            )
                        }}
                    </Query>

                </tbody>
            </table>
            </>
        );
    }
}

export default withApollo(Products);
