import React from 'react';
import { Link } from 'react-router-dom';
import { Query, Mutation } from 'react-apollo';
import { GET_ORDERS, DELELTE_ORDERS } from './queries';
import { LoadingComponent } from '../../components';
import { PAYMENTTYPE, getPaymentType, getTakeout, getUserType, getOffer, numberWithCommas } from '../../constants';

import { toast } from "react-toastify";
import moment from 'moment';
import $ from 'jquery';
import 'jquery-confirm';

import Tooltip from '@material-ui/core/Tooltip';



class Orders extends React.Component {
    state = {
        categories: null,
        checkedAll: false,
        selectedIDs: []
    }

    componentDidMount = () => { }

    handleUpdateCheckbox = (e) => {
        const { actions } = this.form;
        if( actions === undefined ) return;

        const checkboxArray = Array.prototype.slice.call(actions);
        const checkedCheckboxes = checkboxArray.filter(input => input.checked);

        let selectedIDs = [];
        checkedCheckboxes.forEach((item) => {
            selectedIDs.push(item.id);
        });

        this.setState({
            checkedAll: checkedCheckboxes.length === checkboxArray.length,
            selectedIDs
        });
    }

    handleUpdateSelectedAll = (e) => {
        const { actions, actionAll } = this.form;
        if( actions === undefined ) return;

        const checkboxArray = Array.prototype.slice.call(actions);

        checkboxArray.forEach((item) => {
            item.checked = actionAll.checked;
        })

        let selectedIDs = [];
        if( actionAll.checked ) {
            checkboxArray.forEach((item) => {
                selectedIDs.push(item.id);
            });
        }

        this.setState({
            checkedAll: actionAll.checked,
            selectedIDs
        })
    }

    render() {
        const { params } = this.props;
        const { checkedAll, selectedIDs } = this.state;

        return(
            <>
            <form ref={form => this.form = form}>
            <div className="tbl-submit">
                <Mutation
                    mutation={DELELTE_ORDERS}
                    variables={{
                        ids: selectedIDs
                    }}
                    onCompleted={() => {
                        toast.success("주문 삭제가 완료되었습니다.");
                        this.props.history.push('/orders');
                    }}
                    refetchQueries={[{
                        query: GET_ORDERS,
                        variables: { startDate: params.startDate, endDate: params.endDate }
                    }]}
                >
                    {( deleteOrders, result ) => {
                        const { data, loading, error, called } = result;

                        return(
                            <button type="button" className="button-submit" data-type="strong" disabled={selectedIDs.length === 0 || loading} data-state={loading && "loading"}
                                onClick={() => {
                                    $.confirm({
                                        title: 'Warning!',
                                        content: `선택 항목을 정말 삭제하시겠습니까?`,
                                        type: 'warning',
                                        buttons: {
                                            cancel: {
                                                text: 'CANCEL',
                                                btnClass: 'button-normal',
                                                action: function() {
                                                }
                                            },
                                            confirm: {
                                                text: 'OK',
                                                btnClass: 'button-submit strong',
                                                action: function() {
                                                    deleteOrders();
                                                }
                                            }
                                        }
                                    })
                                }} 
                            ><span className="btn-label">선택항목 삭제</span></button>
                        );
                    }}
                </Mutation>
                <Link to={`/orders/add`} className="button-submit"><span className="btn-label">주문추가</span></Link>
            </div>

            <table className="tbl-data">
                <caption>주문목록</caption>
                <colgroup>
                    <col style={{ width: '120px' }}/>
                </colgroup>
                <thead>
                    <tr>
                        <th className="txt-l">
                            <input type="checkbox" id="all" name="actionAll" checked={checkedAll} onChange={(e) => { this.handleUpdateSelectedAll(e); }} />
                            <label htmlFor="all">전체선택</label>
                        </th>
                        <th className="txt-l">주문시간</th>
                        <th className="txt-l">주문내역</th>
                        <th className="txt-c">결제방식</th>
                        <th className="txt-c">테이크아웃</th>
                        <th className="txt-c">손님타입</th>
                        <th className="txt-r">결제금액</th>
                    </tr>
                </thead>
                

                <Query query={GET_ORDERS}
                    variables={{ startDate: params.startDate, endDate: params.endDate }}
                    fetchPolicy={"cache-and-network"}
                >
                    {({ loading, data, error }) => {
                        if( loading ) return <tbody><tr><td colSpan="8" className="none"><LoadingComponent /></td></tr></tbody>
                        if( error )  return <tbody><tr><td colSpan="8" className="none">{error.description}</td></tr></tbody>

                        let tfootTotal = 0;
                        let cardTotal = 0;
                        let cashTotal = 0;
                        let prepaidTotal = 0;

                        let cardCount = 0;
                        let cashCount = 0;
                        let receiptsCount = 0;
                        let prepaidCount = 0;

                        console.log( data.ordersByDate );
                        let listOrders = data.ordersByDate.map((order, index) => {
                            const { id, seq, dcRate, dcPrice, totalPrice, paymentType, cashReceipts, userType, takeout, description, createdAt, items } = order;

                            let receipts = "";
                            tfootTotal += totalPrice;
                            if( paymentType === PAYMENTTYPE.credit ) {
                                cardCount += 1;
                                cardTotal += totalPrice;
                            } else if( paymentType === PAYMENTTYPE.cash ) {
                                cashCount += 1;
                                cashTotal += totalPrice;

                                if( cashReceipts ) {
                                    receiptsCount += 1;
                                    receipts = "(현금영수증)";
                                }
                            } else if( paymentType === PAYMENTTYPE.prepaid ) {
                                prepaidCount += 1;
                                prepaidTotal += totalPrice;
                            }

                            const listItems = items.map((item, index) => {
                                return(
                                    <li key={index}>
                                        {`${getOffer(item.offer)} `}{item.name} ({item.count})
                                    </li>
                                );
                            });

                            let url = `/orders/edit/${id}`;
                            

                            return(
                                <Tooltip key={id} title={description} placement="top">
                                <tr data-id={id} className={paymentType === PAYMENTTYPE.prepaid ? "prepaid" : ""}>
                                    <td className="txt-l">
                                        <input type="checkbox" id={id} name="actions" value={id} onChange={(e) => { this.handleUpdateCheckbox(e); }} />
                                        <label htmlFor={id}>{data.ordersByDate.length - index}</label>
                                    </td>
                                    <td data-label="주문시간" className="txt-l">
                                        <Link to={url}>{moment(createdAt, "x").format("YYYY-MM-DD HH:mm:ss")}</Link>
                                    </td>
                                    <th data-label="주문내역" data-comment={description ? "true" : "false"}>
                                        <ul>
                                            {listItems}
                                        </ul>
                                    </th>
                                    <td data-label="결제방식" className="txt-c">{getPaymentType(paymentType)}{receipts}</td>
                                    <td data-label="테이크아웃" className="txt-c">{getTakeout(takeout)}</td>
                                    <td data-label="손님타입" className="txt-c">{getUserType(userType)}</td>
                                    <td data-label="결제금액" className="txt-r">{numberWithCommas(totalPrice)}</td>
                                </tr>
                                </Tooltip>
                            );
                        });

                        if( data.ordersByDate.length === 0 ) {
                            listOrders = <tr><td colSpan="8" className="none">등록된 주문이 없습니다.</td></tr>
                        }

                        return(
                            <>
                                <tfoot>
                                    <tr>
                                        <th colSpan="2">총 결제금액</th>
                                        <td colSpan="7" className="txt-r price">
                                            <small>(신용카드 {cardCount}건: {numberWithCommas(cardTotal)}, 현금 {cashCount}/({receiptsCount})건: {numberWithCommas(cashTotal)}, 선결제사용 {prepaidCount}건: {numberWithCommas(prepaidTotal)})</small>
                                            &nbsp;&nbsp;&nbsp;
                                            {numberWithCommas(tfootTotal - prepaidTotal)}
                                        </td>
                                    </tr>
                                </tfoot>
                                <tbody>{listOrders}</tbody>
                            </>
                        )
                    }}
                </Query>

            </table>
            </form>
            </>
        )
    }
}

export default Orders;
