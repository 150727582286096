import React from 'react';
import { Link } from 'react-router-dom';
import { Query, Mutation, withApollo } from 'react-apollo';
import { GET_CATEGORIES, GET_SALES_BY_PERIOD, CLOSING_SALE, UPDATE_DAYOFF } from './queries';
import { LoadingComponent } from '../../components';

import { PAYMENTTYPE, getPaymentType, getTakeout, getUserType, getOffer, numberWithCommas, DAYOFWEEK } from '../../constants';

import $ from 'jquery';
import moment from 'moment';
import 'daterangepicker';

import * as queryString from 'query-string';

import { toast } from "react-toastify";


class SalesClosingContainer extends React.Component {
    constructor(props) {
        super(props);

        let parameter = queryString.parse(props.location.search);
        parameter.startDate = parameter.startDate !== undefined ? parameter.startDate : moment().startOf('month').format("YYYY-MM-DD");
        parameter.endDate = parameter.endDate !== undefined ? parameter.endDate : moment().endOf('month').format("YYYY-MM-DD");

        this.state = {
            categories: [],
        }
    }


    getCategories =  async() => {
        const result = await this.props.client.query({
            query: GET_CATEGORIES,
        });
        this.setState({
            categories: result.data.categories
        })
    }


    setDateRangePicker = (startDate, endDate) => {
        let _ = this;
        if( $('input[name="dates"]').data('daterangepicker') !== undefined ) {
            $('input[name="dates"]').data('daterangepicker').remove();
        }

        var myRanges = {};
        myRanges['이번 달(' + moment().startOf('month').format("M") + '월)'] = [moment().startOf('month'), moment().endOf('month')];
        myRanges['지난 달(' + moment().subtract(1, 'month').startOf('month').format("YYYY-M") + '월)'] = [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')];
        myRanges['2달 전(' + moment().subtract(2, 'month').startOf('month').format("YYYY-M") + '월)'] = [moment().subtract(2, 'month').startOf('month'), moment().subtract(2, 'month').endOf('month')];
        myRanges['4달 전(' + moment().subtract(3, 'month').startOf('month').format("YYYY-M") + '월)'] = [moment().subtract(3, 'month').startOf('month'), moment().subtract(3, 'month').endOf('month')];
        myRanges['5달 전(' + moment().subtract(4, 'month').startOf('month').format("YYYY-M") + '월)'] = [moment().subtract(4, 'month').startOf('month'), moment().subtract(4, 'month').endOf('month')];
        myRanges['6달 전(' + moment().subtract(5, 'month').startOf('month').format("YYYY-M") + '월)'] = [moment().subtract(5, 'month').startOf('month'), moment().subtract(5, 'month').endOf('month')];
        
        $('input[name="dates"]').daterangepicker({
            showISOWeekNumbers: true,
            showDropdowns: true,
            alwaysShowCalendars: true,
            startDate: startDate,
            endDate: endDate,
            ranges: myRanges,
            locale: {
                format: 'YYYY-MM-DD'
            }
        }, function(start, end) {
            _.handleDates(start, end);
        });
    }

    componentDidMount = () => {
        document.body.className = '';

        this.getCategories();
        this.setDateRangePicker(this.state.params.startDate, this.state.params.endDate);
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        this.setDateRangePicker(nextState.params.startDate, nextState.params.endDate);
        return true;
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        let parameter = queryString.parse(nextProps.location.search);
        parameter.startDate = parameter.startDate !== undefined ? parameter.startDate : moment().startOf('month').format("YYYY-MM-DD");
        parameter.endDate = parameter.endDate !== undefined ? parameter.endDate : moment().endOf('month').format("YYYY-MM-DD");
        
        if( parameter.startDate !== prevState.startDate || parameter.endDate !== prevState.endDate ) {
            return {
                params: {
                    startDate: parameter.startDate,
                    endDate: parameter.endDate,
                }
            }
        }

        return null;
    }

    handleDates = (start, end) => {
        let currentUrlParams = new URLSearchParams(window.location.search);
        currentUrlParams.set('startDate', start.format('YYYY-MM-DD'));
        currentUrlParams.set('endDate', end.format('YYYY-MM-DD'));

        this.props.history.push(window.location.pathname + "?" + currentUrlParams.toString());
    }

    render() {
        const { categories, params } = this.state;

        var start = moment(params.startDate);
        var end = moment(params.endDate);



        let layoutContentInner = <LoadingComponent />;


        if( categories.length > 0 ) {
            // 카테고리 로드 후
            layoutContentInner = (
                <Query query={GET_SALES_BY_PERIOD}
                    variables={{ startDate: params.startDate, endDate: params.endDate }}
                    fetchPolicy={"cache-and-network"}
                >
                    {({ loading, data, error }) => {
                        let tfootTotal = {
                            salePrice: {
                                total: 0,
                                credit: 0,
                                cash: 0,
                                prepaid: 0,
                            },
                            saleCount: {
                                total: 0,
                                credit: 0,
                                cash: 0,
                                prepaid: 0,
                            }
                        };
                        let workingDays = 0;
                
                        const thead = categories.map((category, index) => {
                            tfootTotal.salePrice[category.uid] = 0;
                            tfootTotal.saleCount[category.uid] = 0;
                            return <th key={index} scope="col" className="txt-r">{category.name}</th>
                        });

                        const columnCount = 7 + categories.length;

                        if( loading ) return <table className="tbl-data"><tbody><tr><td colSpan={columnCount} className="none"><LoadingComponent /></td></tr></tbody></table>
                        if( error )  return <table className="tbl-data"><tbody><tr><td colSpan={columnCount} className="none">{error.description}</td></tr></tbody></table>

                        
                        let listSales = [];
                        const sales = data.salesByPeroid;

                        for( var m = moment(start); m.diff(end, 'days') <= 0; m.add(1, 'days') ) {
                            const rowDate = m.format('YYYY-MM-DD');
                            const rowDayOfWeek = `${rowDate}[${DAYOFWEEK[m.day()]}]`;

                            const rowItem = sales.find((item) => {
                                return item.uid === rowDate;
                            })

                            // 휴무일 버튼
                            let dayoff = rowItem === undefined ? false : rowItem.dayoff;
                            const dayoffSwitch = (
                                <Mutation
                                    mutation={UPDATE_DAYOFF}
                                    variables={{
                                        date: rowDate,
                                        value: !dayoff
                                    }}
                                    onCompleted={() => {
                                        toast.success("휴무일이 업데이트 되었습니다.");
                                    }}
                                    refetchQueries={[{
                                        query: GET_SALES_BY_PERIOD,
                                        variables: { startDate: params.startDate, endDate: params.endDate }
                                    }]}
                                >
                                    {( updateDayoff, result ) => {
                                        const { data, loading, submitting, error, called } = result;
                                        return(
                                            <div className="switch-control txt-none">
                                                <input type="checkbox" id={rowDate} name="" onChange={updateDayoff} defaultChecked={dayoff} disabled={rowItem === undefined} />
                                                <label htmlFor={rowDate}><span>휴무</span></label>
                                            </div>
                                        )
                                    }}
                                </Mutation>
                            );
                            
                            // 정산 버튼
                            const mutationClosingSale = (
                                <Mutation
                                    mutation={CLOSING_SALE}
                                    variables={{
                                        date: rowDate
                                    }}
                                    onCompleted={() => {
                                        toast.success(rowDayOfWeek + " 정산이 완료되었습니다.");
                                    }}
                                    refetchQueries={[{
                                        query: GET_SALES_BY_PERIOD,
                                        variables: { startDate: params.startDate, endDate: params.endDate }
                                    }]}
                                >
                                    {( saleClosingByDate, result ) => {
                                        const { data, loading, submitting, error, called } = result;
                                        return(
                                            <button type="button" className="button-submit" data-size="sm" disabled={submitting || loading} data-state={loading && "loading"}><span className="btn-label" onClick={saleClosingByDate}>정산</span></button>
                                        )
                                    }}
                                </Mutation>
                            );

                            let rowSale = (
                                    <tr key={rowDate}>
                                        <td data-label="날짜">{rowDayOfWeek}</td>
                                        <td colSpan={columnCount - 3} className="txt-c">정산된 내역이 없습니다.</td>
                                        <td className="txt-r" data-label="휴무일 지정">
                                            {dayoffSwitch}
                                        </td>
                                        <td className="txt-r" data-label="정산">
                                            {mutationClosingSale}
                                        </td>
                                    </tr>
                            );

                            if( rowItem !== undefined ) {
                                if( rowItem.dayoff ) {
                                    rowSale = (
                                        <tr key={rowDate}>
                                            <td data-label="날짜">{rowDayOfWeek}</td>
                                            <td colSpan={columnCount - 3} className="txt-c">휴무일 입니다.</td>
                                            <td className="txt-r" data-label="휴무일 지정">
                                                {dayoffSwitch}
                                            </td>
                                            <td className="txt-r"></td>
                                        </tr>
                                    );
                                } else if( rowItem.saleCount.total !== 0 ) {
                                    workingDays++;

                                    const tdCategories = categories.map((category, index) => {
                                        const exist = rowItem.categoriesSales.find((rowCategory) => {
                                            return rowCategory.categoryID === category.id
                                        })

                                        if( exist !== undefined ) {
                                            tfootTotal.salePrice[category.uid] += exist.price;
                                            tfootTotal.saleCount[category.uid] += exist.count;
                                        }

                                        return <td key={index} className="txt-r" data-label={category.name}>
                                                {exist === undefined ? (
                                                    <>0원<br/>(0건)</>
                                                ) : (
                                                    <a href="#">{numberWithCommas(exist.price)}<br/>({exist.count}건)</a>
                                                )}
                                            </td>
                                    });
                        
                                    rowSale = (
                                        <tr key={rowDate}>
                                            <td className="txt-l" data-label="날짜">
                                                <Link to={`/revenue/saleByTime?date=${rowDate}`}>{rowDayOfWeek}</Link>
                                            </td>
                                            <td className="txt-r" data-label="총매출(건수)">
                                                {numberWithCommas(rowItem.salePrice.total)}<br/>({rowItem.saleCount.total}건)
                                            </td>
                                            <td className="txt-r" data-label="카드">
                                                {numberWithCommas(rowItem.salePrice.credit)}<br/>({rowItem.saleCount.credit}건)
                                            </td>
                                            <td className="txt-r" data-label="현금">
                                                {numberWithCommas(rowItem.salePrice.cash)}<br/>({rowItem.cashReceiptsCount}/{rowItem.saleCount.cash}건)
                                            </td>
                                            <td className="txt-r" data-label="선결제 사용">
                                                {numberWithCommas(rowItem.salePrice.prepaid)}<br/>({rowItem.saleCount.prepaid}건)
                                            </td>

                                            {tdCategories}

                                            <td className="txt-r" data-label="휴무일 지정">
                                                {dayoffSwitch}
                                            </td>
                                            <td className="txt-r" data-label="정산">
                                                {mutationClosingSale}
                                            </td>
                                        </tr>                                        
                                    );

                                    // 총합/총건수 계산
                                    tfootTotal.salePrice.total += rowItem.salePrice.total;
                                    tfootTotal.saleCount.total += rowItem.saleCount.total;

                                    tfootTotal.salePrice.credit += rowItem.salePrice.credit;
                                    tfootTotal.saleCount.credit += rowItem.saleCount.credit;

                                    tfootTotal.salePrice.cash += rowItem.salePrice.cash;
                                    tfootTotal.saleCount.cash += rowItem.saleCount.cash;

                                    tfootTotal.salePrice.prepaid += rowItem.salePrice.prepaid;
                                    tfootTotal.saleCount.prepaid += rowItem.saleCount.prepaid;
                                }

                                listSales.push(rowSale);
                            } else {
                                listSales.push(rowSale);
                            }
                        }

                        /* TFoot 써머리 영역 */
                        const tfootCategories = categories.map((category, index) => {
                            return <td key={index} className="txt-r" data-label={category.name}>{numberWithCommas(tfootTotal.salePrice[category.uid])}<br/>({tfootTotal.saleCount[category.uid]}건)</td>
                        });

                
                        return(
                            <>
                            <table className="tbl-data">
                                <caption>주문 내역</caption>
                                <thead>
                                    <tr>
                                        <th scope="col" className="txt-l">날짜</th>
                                        <th scope="col" className="txt-r">총매출(건수)</th>
                                        <th scope="col" className="txt-r">카드</th>
                                        <th scope="col" className="txt-r">현금</th>
                                        <th scope="col" className="txt-r">선결제</th>

                                        {thead}
                                        
                                        <th scope="col" className="txt-r">휴무</th>
                                        <th scope="col" className="txt-r">정산</th>
                                    </tr>
                                </thead>
                            
                                <tfoot>
                                    <tr>
                                        <th className="txt-l" data-label="일 평균">일 평균</th>
                                        <td colSpan="3" className="txt-r price" data-label="일 평균">
                                            <strong>{numberWithCommas(Math.floor(tfootTotal.salePrice.total/workingDays))}(영업일수: {workingDays})</strong>
                                        </td>
                                        <td colSpan="7"></td>
                                    </tr>
                                    <tr>
                                        <th className="txt-l">매출 합</th>
                                        <td className="txt-r price" data-label="총매출">{numberWithCommas(tfootTotal.salePrice.total)}<br/>({tfootTotal.saleCount.total}건)</td>
                                        <td className="txt-r price" data-label="카드">{numberWithCommas(tfootTotal.salePrice.credit)}<br/>({tfootTotal.saleCount.credit}건)</td>
                                        <td className="txt-r price" data-label="현금">{numberWithCommas(tfootTotal.salePrice.cash)}<br/>({tfootTotal.saleCount.cash}건)</td>
                                        <td className="txt-r price" data-label="선결제사용">{numberWithCommas(tfootTotal.salePrice.prepaid)}<br/>({tfootTotal.saleCount.prepaid}건)</td>
                                        {tfootCategories}
                                        <td className="txt-r"></td>
                                        <td className="txt-r"></td>
                                    </tr>
                                </tfoot>
                                <tbody>{listSales}</tbody>
                            </table>
                            </>
                        );
                    }}
                </Query>
            );
        }

        return(
            <>
            <article className="layout-contents">
                <div className="layout-content-utils">
                    <div className="content-view">
                        <h1 className="page-title">기간별 정산내역</h1>
                    </div>

                    <div className="utils-view">
                        <fieldset className="utils-filter">
                            <legend></legend>

                            <input type="text" id="" name="dates" />
                        </fieldset>
                    </div>
                </div>

                <div className="layout-content-inner">
                    
                    {layoutContentInner}

                </div>
            </article>
            </>
        );
    }
}

export default withApollo(SalesClosingContainer);
