import React from 'react';

class AuthMenuLayout extends React.PureComponent {
  render() {
    return (
        <main role="main">
            <div className="layout-contents-container">
                {this.props.children}
            </div>
        </main>
    );
  }
}

export default AuthMenuLayout;
