import React from 'react';
import { Query } from 'react-apollo';
import { GET_CATEGORIES } from './queries';


const OptionsByCategory = () => (
    <Query query={GET_CATEGORIES}>
        {({ loading, data, error }) => {
            if( loading ) return <option>로딩중...</option>
            if( error )  return <option>{error.description}</option>

            const optionsCategories = data.categories.map((category) => {
                return(
                    <option key={category.id} value={category.id}>{category.name}</option>
                );
            });

            return(
                <>
                    {optionsCategories}
                </>
                
            );
        }}
    </Query>
);

export default OptionsByCategory;