import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import App from '../App';
import client from '../apollo';

import { ToastContainer, Slide } from "react-toastify";


const Root = () => (
	<ApolloProvider client={client}>
		<BrowserRouter>
			<App />
		</BrowserRouter>
		<ToastContainer position="top-right" transition={Slide} hideProgressBar newestOnTop closeOnClick pauseOnVisibilityChange />
	</ApolloProvider>
);

export default Root;

