import React from 'react';
import { withApollo } from 'react-apollo';
import { GET_PRODUCTS } from './queries';
import { LoadingComponent } from '../../components';
import { OFFER, numberWithCommas } from '../../constants';


class OrderItems extends React.Component {
    state = {
        loading: false,
        orderItems: []
    }

    componentDidMount = () => {
        this.handleGetProducts();
    }

    handleGetProducts = async() => {
        this.setState({
            ...this.state,
            loading: true
        });

        const result = await this.props.client.query({
            query: GET_PRODUCTS,
            variables: {
                sale: true,
                category: 'all'
            },
        });

        let availableProducts = [];
        result.data.products.forEach(product => {
            if( product.hot.available ) {
                let temp = JSON.parse(JSON.stringify(product));
                temp.isIced = false;
                availableProducts.push(temp);
            }
            if( product.iced.available ) {
                let temp = JSON.parse(JSON.stringify(product));
                temp.isIced = true;
                availableProducts.push(temp);
            }
            if( !product.hot.available && !product.iced.available ) {
                let temp = JSON.parse(JSON.stringify(product));
                temp.isDessert = true;
                availableProducts.push(temp);
            }
        });
        
        this.setState({
            loading: false,
            orderItems: availableProducts
        });
    }

    handleAddItem = (e, product, iced) => {
        const { onItemSelect } = this.props;
        onItemSelect(product);
    }

    render() {
        const { filters } = this.props;
        const { loading, orderItems } = this.state;

        if( loading ) return <ul><li className="none"><LoadingComponent /></li></ul>

        const options = filters;
        function filterOffer(item) {
            if( !item.isIced && options.offer === OFFER.hot ) {
                return true;
            }

            if( item.isIced && options.offer === OFFER.iced ) {
                return true;
            }

            if( item.isDessert ) {
                return true;
            }

            return false;
        }

        function filterCategory(item) {
            if( options.category === 'all' ) return true;
            if( item.category.id === options.category ) return true;
            return false;
        }

        let items = orderItems.filter(filterOffer).filter(filterCategory);

        let listItems = null;
        if( items ) {
            listItems = items.map((product, index) => {
                const { id, category, name, price, iced, isIced, isDessert } = product;
                let iconOffer = "";
                if( !isDessert ) {
                    iconOffer = isIced ? <span className="iced">ICE</span> : <span className="hot">HOT</span>;
                }

                return(
                    <li key={`${id}_${index}`}>
                        <button type="button" className="product-item" data-category={category.uid} onClick={(event) => {this.handleAddItem(event, product, iced);}}>
                            {iconOffer}
                            {name}
                            <div className="info">
                                <strong className="price">({numberWithCommas(price)})</strong>
                            </div>
                        </button>
                    </li>
                );
            });
        }



        return(
            <>
            <ul className="list-products">
            {listItems}
            </ul>
            </>
        )
    }
}

export default withApollo(OrderItems);
