import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import { AuthRoute } from './authroute';

import {
    SideMenuLayout,
    AuthMenuLayout,
    OrderLayout
} from './layout';

import {
    MainPage,
    LoginPage,

    ProductsPage,
    AddProductPage,
    EditProductPage,

    OrdersPage,
    AddOrderPage,

    SalesClosingPage,
    SalesByTimePage,
} from './pages';


const DefaultRoute = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )} />
)

class App extends Component {
    render() {
        return (
            <div className="layout-wrap">
            <Switch>
                <DefaultRoute exact path="/login" layout={AuthMenuLayout} component={LoginPage} />

                <AuthRoute path="/products/edit/:id" layout={SideMenuLayout} component={EditProductPage} />
                <AuthRoute path="/products/add" layout={SideMenuLayout} component={AddProductPage} />
                <AuthRoute exact path="/products" layout={SideMenuLayout} component={ProductsPage} />

                <AuthRoute path="/orders/edit/:id" layout={OrderLayout} component={AddOrderPage} />
                <AuthRoute path="/orders/add" layout={OrderLayout} component={AddOrderPage} />
                <AuthRoute exact path="/orders" layout={SideMenuLayout} component={OrdersPage} />

                <AuthRoute exact path="/revenue/saleByTime" layout={SideMenuLayout} component={SalesByTimePage} />
                <AuthRoute exact path="/revenue" layout={SideMenuLayout} component={SalesClosingPage} />

                {/* <AuthRoute exact path="/statistics/sales" layout={SideMenuLayout} component={SalesByPeriodPage} /> */}

                <AuthRoute exact path="/" layout={SideMenuLayout} component={MainPage} />
                <AuthRoute layout={SideMenuLayout} component={MainPage} />
            </Switch>
            </div>
        );
    }
}

export default withRouter(App);
