import { gql } from 'apollo-boost';


export const GET_ORDERS = gql`
    query getOrders($startDate: String, $endDate: String) {
        ordersByDate(startDate: $startDate, endDate: $endDate) {
            id
            orderDay
            seq
            dcRate
            originPrice
            dcPrice
            countTakeout
            dcTakeoutPrice
            countTumbler
            dcTumblerPrice
            totalPrice
            paymentType
            cashReceipts
            takeout
            userType
            description
            cellPhone
            createdAt
            items {
                categoryID
                productID
                categoryOrder
                name
                offer
                count
                price
                order
            }
        }
    } 
`;

export const GET_ORDER = gql`
    query getOrder($id: String!) {
        order(id: $id) {
            id
            orderDay
            seq
            dcRate
            originPrice
            dcPrice
            countTakeout
            dcTakeoutPrice
            countTumbler
            dcTumblerPrice
            totalPrice
            paymentType
            cashReceipts
            takeout
            userType
            description
            cellPhone
            createdAt
            items {
                categoryID
                productID
                categoryOrder
                name
                offer
                count
                price
                order
            }
        }
    }
`;

export const ADD_ORDER = gql`
    mutation AddOrder($orderDay: String!, $dcRate: Int!, $originPrice: Int!, $dcPrice: Int!, $countTakeout: Int!, $dcTakeoutPrice: Int!, $countTumbler: Int!, $dcTumblerPrice: Int!, $totalPrice: Int!, $paymentType: Int!, $cashReceipts: Boolean!, $takeout: Boolean!, $userType: Int!, $description: String, $cellPhone: String!, $createdAt: String!, $items: [InputOrderDetail]!) {
        addOrder(orderDay: $orderDay, dcRate: $dcRate, originPrice: $originPrice, dcPrice: $dcPrice, countTakeout: $countTakeout, dcTakeoutPrice: $dcTakeoutPrice, countTumbler: $countTumbler, dcTumblerPrice: $dcTumblerPrice, totalPrice: $totalPrice, paymentType: $paymentType, cashReceipts: $cashReceipts, takeout: $takeout, userType: $userType, description: $description, cellPhone: $cellPhone, createdAt: $createdAt, items: $items ) {
            id
            orderDay
            seq
            dcRate
            originPrice
            dcPrice
            countTakeout
            dcTakeoutPrice
            countTumbler
            dcTumblerPrice
            totalPrice
            paymentType
            cashReceipts
            takeout
            userType
            description
            cellPhone
            createdAt
            items {
                categoryID
                productID
                categoryOrder
                name
                offer
                count
                price
                order
            }
        }
    }
`;

export const UPDATE_ORDER = gql`
    mutation UpdateOrder($id: String!, $orderDay: String!, $dcRate: Int!, $originPrice: Int!, $dcPrice: Int!, $countTakeout: Int!, $dcTakeoutPrice: Int!, $countTumbler: Int!, $dcTumblerPrice: Int!, $totalPrice: Int!, $paymentType: Int!, $cashReceipts: Boolean!, $takeout: Boolean!, $userType: Int!, $description: String, $cellPhone: String!, $createdAt: String!, $items: [InputOrderDetail]!) {
        updateOrder(id: $id, orderDay: $orderDay, dcRate: $dcRate, originPrice: $originPrice, dcPrice: $dcPrice, countTakeout: $countTakeout, dcTakeoutPrice: $dcTakeoutPrice, countTumbler: $countTumbler, dcTumblerPrice: $dcTumblerPrice, totalPrice: $totalPrice, paymentType: $paymentType, cashReceipts: $cashReceipts, takeout: $takeout, userType: $userType, description: $description, cellPhone: $cellPhone, createdAt: $createdAt, items: $items ) {
            id
            orderDay
            seq
            dcRate
            originPrice
            dcPrice
            countTakeout
            dcTakeoutPrice
            countTumbler
            dcTumblerPrice
            totalPrice
            paymentType
            cashReceipts
            takeout
            userType
            description
            cellPhone
            createdAt
            items {
                categoryID
                productID
                categoryOrder
                name
                offer
                count
                price
                order
            }
        }
    }
`;

export const DELELTE_ORDERS = gql`
    mutation DeleteOrders($ids: [String!]!) {
        deleteOrders(ids: $ids) {
            result
            ids
        }
    }
`;

export const DELELTE_ORDER = gql`
    mutation DeleteOrder($id: String!) {
        deleteOrder(id: $id) {
            id
        }
    }
`;


export const GET_PRODUCTS = gql`
    query getProducts($sale: Boolean, $category: String) {
        products(sale: $sale, category: $category) {
            id
            category {
                id
                name
                uid
                order
            }
            name
            price
            hot {
                available
                difference
            }
            iced {
                available
                difference
            }
            sale
            takeout
            order
            description
        }
    } 
`;


export const GET_CATEGORIES = gql`
    query {
        categories {
            id
            name
            uid
            order
            description
        }
    } 
`;
