import React from 'react';


const SegmentedControl = ({names, options, onChange}) => {
    const controls = options.map((item, index) => {
        const { title, value } = item;
        return(
            <React.Fragment key={index}>
                <input type="radio" id={value} name={names} value={value} onChange={(e) => {onChange(e)}} />
                <label htmlFor={value}>{title}</label>
            </React.Fragment>
        )
    });

    return(
        <div className="segmented-control">
            {controls}
        </div>
    );
};

export default SegmentedControl;