import React from 'react';
import { Link } from 'react-router-dom';
import { SaleChartByTime } from '../../components';
import { numberWithCommas } from '../../constants';


import moment from 'moment';


class MainPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: moment().format("YYYY-MM-DD"),
            totalPrice: 0,
        }
    }

    componentDidMount = () => {
        document.body.className = 'page-main';
    }

    handleCompleteByTime = (total) => {
        this.setState({
            totalPrice: total
        })
    }

    render() {
        const { totalPrice, date } = this.state;

        return(
        <>
            <article className="layout-contents">
                <div className="layout-content-utils">
                    <div className="content-view">
                        <h1 className="overview-title"><span>Today Overview :</span> <strong className="today">{numberWithCommas(totalPrice)}</strong></h1>
                    </div>

                    <div className="utils-view">
                        <Link to={`/orders/add`} className="button-submit"><span className="btn-label">주문추가</span></Link>
                    </div>
                </div>

                <div className="layout-content-inner">

                    <h2 className="overview-sub">시간대별 주문 건수 및 일매출</h2>
                    <SaleChartByTime date={date} onComplete={this.handleCompleteByTime} />


                </div>
            </article>
        </>
        )
    }
}

export default MainPage;
