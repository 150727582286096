import ApolloClient,  { InMemoryCache } from 'apollo-boost';
import { AUTH_TOKEN } from './constants';
import { toast } from "react-toastify";
import { removeToken } from './authroute';

const isDev = process.env.NODE_ENV === "development";
const uri = isDev ? 'http://localhost:4000/graphql' : 'https://pos-modernb.appspot.com/graphql'

const client = new ApolloClient({
    uri: uri,

    request: async (operation) => {
      const token = localStorage.getItem(AUTH_TOKEN);

      operation.setContext({
        headers: {
          Authorization: token ? `Bearer ${token}` : ''
        }
      });
    },

    onError: ({ graphQLErrors, networkError }) => {
      if( graphQLErrors ) {
        console.log(graphQLErrors);
        graphQLErrors.map(({ extensions, message, locations, path }) => {
          if( extensions.code === "UNAUTHENTICATED" ) {
            removeToken();
            toast.error(message);
          }
          
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          );
        });
      }

      if( networkError ) {
        toast.error(`Network error: ${networkError}`);
        console.log(`[Network error]: ${networkError}`)
      }

    },

    cache: new InMemoryCache({
      dataIdFromObject: object => object.id || object._id
    }),
});

export default client;
