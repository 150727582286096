import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import firebase from 'firebase';
import { AUTH_TOKEN } from './constants'
import { toast } from "react-toastify";


const isDev = process.env.NODE_ENV === "development";

var firebaseConfig = isDev ? {
    apiKey: "AIzaSyCNZmM-NksAP1gKui3ujRaU_1vGx4hzsOw",
    authDomain: "dev-pos-cafemodernb.firebaseapp.com",
    databaseURL: "https://dev-pos-cafemodernb.firebaseio.com",
    projectId: "dev-pos-cafemodernb",
    storageBucket: "dev-pos-cafemodernb.appspot.com",
    messagingSenderId: "889149516051"
} : {
    apiKey: "AIzaSyAIEx9ZjiYAJxfdcUqx4lMIgX5qbPa3_C0",
    authDomain: "pos-modernb.firebaseapp.com",
    databaseURL: "https://pos-modernb.firebaseio.com",
    projectId: "pos-modernb",
    storageBucket: "pos-modernb.appspot.com",
    messagingSenderId: "634158797667"
};
firebase.initializeApp(firebaseConfig);


export const clientFirebase = firebase;

export const getToken = () => localStorage.getItem(AUTH_TOKEN);
export const removeToken = () => localStorage.removeItem(AUTH_TOKEN);

export const AuthRoute = ({ component: Component, layout: Layout, ...rest }) =>
    <Route
        {...rest}
        render={props => {
            if( !getToken() ) {
               toast.error("로그인이 필요한 페이지입니다.");
            }
            return(
                getToken()
                ? <Layout><Component auth={getToken()} {...props} /></Layout>
                : <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            )
        }}
    />;
