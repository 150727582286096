import React from 'react';
import { withApollo } from 'react-apollo';
import { GET_SALES_BY_PERIOD } from './queries';

import { SegmentedControl } from '../../components';


/// amchart
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_animated);



class SalesByPeriodContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            sales: [],
        }
    }

    componentDidMount = () => {
        document.body.className = '';

        this.handleGetSalesByPeriod();
    }

    componentWillUnmount = () => {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    }
    

    handleGetSalesByPeriod = async() => {
        this.setState({
            ...this.state,
            loading: true
        });

        const results = await this.props.client.query({
            query: GET_SALES_BY_PERIOD,
            variables: {
                period: "daily",
                startDate: "2019-01-01",
                endDate: "2019-12-31",
            },
        });

        this.setState({
            loading: false,
            sales: results.data.salesByPeroid
        });
        // this.handleChartContainer();
        this.handleChartTest();
    }

    handleChartTest = () => {
        let chart = am4core.create("chartContainer", am4charts.XYChart);
        chart.colors.list = [
            am4core.color("#cc7b19"),
            am4core.color("#D65DB1"),
            am4core.color("#FF6F91"),
            am4core.color("#FF9671"),
            am4core.color("#FFC75F"),
            am4core.color("#F9F871")
        ];

        const data = this.state.sales;
        const groupedOrderDay = this.groupBy(data, item => item.orderDay);

        let result = [];
        groupedOrderDay.forEach((group) => {
            let total = group[0].price;

            if( group.length > 1 ) {
                total = group.reduce((result, { price }) => {
                    return result + price
                }, 0)
            }

            result.push({
                date: new Date(group[0].orderDay),
                totalPrice: total
            })
        })

        chart.data = result;

        chart.colors.step = 2;
        chart.maskBullets = false;
        
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0;
        dateAxis.renderer.minGridDistance = 50;
        dateAxis.renderer.grid.template.disabled = true;
        dateAxis.renderer.fullWidthTooltip = true;

        let totalPriceAxis = chart.yAxes.push(new am4charts.ValueAxis());
        totalPriceAxis.title.text = "금액";
        totalPriceAxis.renderer.grid.template.disabled = true;

        
        let totalPriceSeries = chart.series.push(new am4charts.ColumnSeries());
        totalPriceSeries.dataFields.valueY = "totalPrice";
        totalPriceSeries.dataFields.dateX = "date";
        totalPriceSeries.yAxis = totalPriceAxis;
        totalPriceSeries.tooltipText = "{valueY} 원";
        totalPriceSeries.name = "매출";
        totalPriceSeries.columns.template.fillOpacity = 0.7;
        totalPriceSeries.columns.template.propertyFields.strokeDasharray = "dashLength";
        totalPriceSeries.columns.template.propertyFields.fillOpacity = "alpha";

        

        // Add legend
        chart.legend = new am4charts.Legend();

        // Add cursor
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.fullWidthLineX = true;
        chart.cursor.xAxis = dateAxis;
        chart.cursor.lineX.strokeOpacity = 0;
        chart.cursor.lineX.fill = am4core.color("#000");
        chart.cursor.lineX.fillOpacity = 0.1;


        this.chart = chart;        
    }

    handleChartContainer = () => {
        let chart = am4core.create("chartContainer", am4charts.XYChart);

        chart.scrollbarX = new am4core.Scrollbar();
        chart.paddingRight = 20;

        const data = this.state.sales;
        const grouped = this.groupBy(data, item => item.orderDay);

        let result = [];
        grouped.forEach((group) => {
            let total = group[0].price;

            if( group.length > 1 ) {
                total = group.reduce((result, { price }) => {
                    return result + price
                }, 0)
            }

            result.push({
                date: new Date(group[0].label),
                value: total
            })
        })
        chart.data = result;
    
        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
    
        let series = chart.series.push(new am4charts.ColumnSeries());
        series.sequencedInterpolation = true;
        series.dataFields.dateX = "date";
        series.dataFields.valueY = "value";
        series.tooltipText = "[{dateX}: bold]{valueY}[/]";
        series.columns.template.strokeWidth = 0;
        series.tooltip.pointerOrientation = "vertical";

        series.columns.template.column.cornerRadiusTopLeft = 5;
        series.columns.template.column.cornerRadiusTopRight = 5;
        series.columns.template.column.fillOpacity = 0.8;
        
        // on hover, make corner radiuses bigger
        var hoverState = series.columns.template.column.states.create("hover");
        hoverState.properties.cornerRadiusTopLeft = 5;
        hoverState.properties.cornerRadiusTopRight = 5;
        hoverState.properties.fillOpacity = 1;

        series.columns.template.adapter.add("fill", (fill, target)=>{
            return chart.colors.getIndex(target.dataItem.index);
        })
          
        chart.cursor = new am4charts.XYCursor();

        this.chart = chart;        
    }


    render() {
        return(
            <>
                <article className="layout-contents">
                    <div className="layout-content-utils">
                        <div className="content-view">
                            <h1 className="page-title">기간별 매출</h1>
                        </div>

                        <div className="utils-view">
                            <fieldset className="utils-filter">
                                <legend>filter</legend>
                                
                                <SegmentedControl
                                    names={"period"}
                                    options={[
                                        {
                                            value: 'daily',
                                            title: '일별'
                                        },
                                        {
                                            value: 'weekly',
                                            title: '주별'
                                        },
                                        {
                                            value: 'monthly',
                                            title: '월별'
                                        }
                                    ]}
                                    onChange={() => {}}
                                />
                            </fieldset>
                        </div>
                    </div>

                    <div className="layout-content-inner">
                        
                        <div id="chartContainer" className="chart"></div>

                    </div>
                </article>
            </>
        );
    }
}

export default withApollo(SalesByPeriodContainer);
