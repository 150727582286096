import { gql } from 'apollo-boost';

export const GET_CATEGORIES = gql`
query {
    categories {
        id
        name
        uid
        order
        description
    }
} 
`;


export const ORDERS_BY_TIME = gql`
    query getOrdersByTime($date: String!) {
        ordersByTime(date: $date) {
            orderDay
            price
            paymentType
            takeout
            createdAt
            items {
                categoryID
                productID
                name
                offer
                count
            }
        }
    } 
`;

export const GET_ORDERS = gql`
    query getOrders($startDate: String, $endDate: String) {
        ordersByDate(startDate: $startDate, endDate: $endDate) {
            id
            orderDay
            seq
            dcRate
            originPrice
            dcPrice
            countTumbler
            dcTumblerPrice
            totalPrice
            paymentType
            cashReceipts
            takeout
            userType
            description
            cellPhone
            createdAt
            items {
                categoryID
                productID
                categoryOrder
                name
                offer
                count
                price
                order
            }
        }
    } 
`;
