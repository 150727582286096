import React from 'react';
import { Mutation, withApollo } from 'react-apollo';
import { GET_ORDERS, GET_ORDER, ADD_ORDER, UPDATE_ORDER, DELELTE_ORDER } from './queries';

import { Form, FormSpy, Field } from 'react-final-form';
import { FieldArray } from "react-final-form-arrays";
import arrayMutators from "final-form-arrays";

import { LoadingComponent } from '../../components';

import validates from './validates';

import $ from 'jquery';
import moment from 'moment';
import 'daterangepicker';
import { numberWithCommas, getOptionsPaymentType, getOptionsUserType, getOptionsTakeout, PAYMENTTYPE } from '../../constants';
import { toast } from "react-toastify";


const dcRateOptions = [
    {
        label: "없음",
        value: "0"
    },
    {
        label: "지인 (-10%)",
        value: "10"
    },
    {
        label: "직원 (-50%)",
        value: "50"
    },
    {
        label: "서비스 (-100%)",
        value: "100"
    }
];

const paymentTypeOptions = getOptionsPaymentType();
const takeoutOptions = getOptionsTakeout();
const userTypeOptions = getOptionsUserType();

const SegmentedControlComponet = ({ fields, options }) => {
    const controls = options.map((option, index) => {
        return(
            <Field key={index} name={fields.name} type="radio" value={option.value}>
                {({ input, meta }) => (
                    <>
                    <input {...input} type="radio" id={input.name + "_" + index} />
                    <label htmlFor={input.name + "_" + index}>{option.label}</label>
                    </>
                )}
            </Field>
        );
    });

    return(
        <div className="segmented-control">
            {controls}
        </div>
    );
};

const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value === is ? children : null)}
    </Field>
);

const ConditionNot = ({ when, isNot, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => (value !== isNot ? children : null)}
    </Field>
);


const OrderForms = ({history, result, initData, submitAction, updateData, resetData}) => {
    const { data, loading, error, called } = result;

    return(
        <Form
            onSubmit={(values) => {
                const newItems = values.items.map((item) => {
                    const newItem = {
                        ...item,
                        categoryOrder: parseInt(item.categoryOrder, 10),
                        count: parseInt(item.count, 10),
                        offer: parseInt(item.offer, 10),
                        price: parseInt(item.price, 10)
                    };
                    return newItem;
                });

                const newValues = {
                    id: values.id,
                    orderDay: moment(values.createdAt).format('YYYY-MM-DD'),
                    dcRate: parseInt(values.dcRate, 10),
                    originPrice: parseInt(values.originPrice.replace(/,/g,""), 10),
                    dcPrice: parseInt(values.dcPrice.replace(/,/g,""), 10),
                    countTakeout: parseInt(values.countTakeout.replace(/,/g,""), 10),
                    dcTakeoutPrice: parseInt(values.dcTakeoutPrice.replace(/,/g,""), 10),
                    countTumbler: parseInt(values.countTumbler.replace(/,/g,""), 10),
                    dcTumblerPrice: parseInt(values.dcTumblerPrice.replace(/,/g,""), 10),
                    totalPrice: parseInt(values.totalPrice.replace(/,/g,""), 10),
                    paymentType: values.dcRate === "100" ? PAYMENTTYPE.none : parseInt(values.paymentType, 10),
                    cashReceipts: values.dcRate === "100" ? false : values.cashReceipts,
                    takeout: values.takeout === "true",
                    userType: parseInt(values.userType, 10),
                    description: !values.description ? "" : values.description,
                    cellPhone: !values.cellPhone ? "" : values.cellPhone,
                    createdAt: moment(values.createdAt),
                    items: newItems
                }
                
                submitAction({
                    variables: newValues
                });
            }}
            mutators={{ ...arrayMutators }}
            initialValues={initData}
            validate={validates}
            render={({ handleSubmit, submitting, pristine, invalid, values, variables, validate, meta }) => {
                return(
                <>
                    <div className="layout-order-header">
                        <h2 className="title">{values.orderDay}</h2>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <fieldset className="forms">
                            <legend>주문 내역</legend>

                            <Field name="id" component="input" type="hidden" />
                            <Field name="orderDay" component="input" type="hidden" />
                            <dl className="order-summary">
                                <Field name="createdAt">
                                    {({ input, meta }) => (
                                        <>
                                        <dt><label htmlFor={input.name}>주문 시간</label></dt>
                                        <dd>
                                            <div className="form-item datepicker">
                                                <input {...input} type="text" id={input.name} name={input.name} className="block dates" readOnly />
                                                {values.id ? "" : (<button type="button" className="button-normal" onClick={resetData}>리셋</button>)}
                                            </div>
                                        </dd>
                                        </>
                                    )}
                                </Field>

                                <dt>주문 항목</dt>
                                <dd>
                                    <FieldArray name="items">
                                        {({ fields }) => {
                                            if( fields.length === 0 ) return(<ul className="list-orders"><li className="none">상품을 추가해주세요.(필수 항목입니다.)</li></ul>);

                                            const items = fields.map((name, index) => {
                                                const item = fields.value[index];
                                                if( item === undefined ) return(<li key={index}></li>);

                                                let iconOffer = "";
                                                if( item.offer === 1 ) {
                                                    iconOffer = <span className="iced">ICED</span>;
                                                } else if( item.offer === 2) {
                                                    iconOffer = "";
                                                }

                                                return(
                                                    <li key={index}>
                                                        <span className="title">{item.name} {iconOffer}</span>

                                                        <Field name={`${name}.name`} component="input" type="hidden" />
                                                        <Field name={`${name}.categoryID`} component="input" type="hidden" />
                                                        <Field name={`${name}.prodcutID`} component="input" type="hidden" />
                                                        <Field name={`${name}.price`} component="input" type="hidden" />
                                                        <Field name={`${name}.count`} component="select">
                                                            <option value="1">1</option>
                                                            <option value="2">2</option>
                                                            <option value="3">3</option>
                                                            <option value="4">4</option>
                                                            <option value="5">5</option>
                                                            <option value="6">6</option>
                                                            <option value="7">7</option>
                                                            <option value="8">8</option>
                                                            <option value="9">9</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                            <option value="13">13</option>
                                                            <option value="14">14</option>
                                                            <option value="15">15</option>
                                                        </Field>
                                                        <strong className="price">{numberWithCommas(item.price * item.count)}</strong>
                                                        
                                                        <button type="button" className="btn-delete" onClick={() => { fields.remove(index); }}>삭제</button>
                                                    </li>
                                                )
                                            });

                                            return(
                                                <ul className="list-orders">{items}</ul>
                                            );
                                        }}
                                    </FieldArray>
                                </dd>


                                <dt>할인율 선택</dt>
                                <dd>
                                    <FieldArray name="dcRate" component={SegmentedControlComponet} options={dcRateOptions} />
                                </dd>

                                <dt className="row">금액</dt>
                                <dd className="row">
                                    <div className="form-price-value">
                                        <Field name="originPrice" component="input" type="text" readOnly />원
                                    </div>
                                </dd>

                                <dt className="row">할인 금액</dt>
                                <dd className="row">
                                    <div className="form-price-value">
                                        <Field name="dcPrice" component="input" type="text" readOnly />원
                                    </div>
                                </dd>

                                <dt className="row">테이크아웃 추가 할인</dt>
                                <dd className="row">
                                    <div className="form-price-value">
                                        <Field name="countTakeout" component="select">
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                            <option value="9">9</option>
                                        </Field>
                                        <Field name="dcTakeoutPrice" component="input" type="text" readOnly />원
                                    </div>
                                </dd>

                                <dt className="row">개인컵 추가 할인</dt>
                                <dd className="row">
                                    <div className="form-price-value">
                                        <Field name="countTumbler" component="select">
                                            <option value="0">0</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                        </Field>
                                        <Field name="dcTumblerPrice" component="input" type="text" readOnly />원
                                    </div>
                                </dd>

                                <dt className="row">결제 금액</dt>
                                <dd className="row">
                                    <div className="form-price-value" data-value="payment">
                                        <Field name="totalPrice" component="input" type="text" readOnly />원
                                    </div>
                                </dd>

                                <dt className="row">사용자 휴대폰정보</dt>
                                <dd className="row">
                                    <Field name="cellPhone">
                                        {({ input, meta }) => (
                                            <div className="form-item">
                                                <input {...input} type="text" id={input.name} className="block" placeholder="'-'를 제외한 숫자만 입력하세요." data-invalid={meta.touched && meta.invalid} />
                                                {meta.touched && meta.error && <p className="validation-message">{meta.error}</p>}
                                            </div>
                                        )}
                                    </Field>
                                </dd>


                                <dt>부가정보</dt>
                                <dd>
                                    <ConditionNot when="dcRate" isNot={"100"}>
                                        <FieldArray name="paymentType" component={SegmentedControlComponet} options={paymentTypeOptions} />
                                        <Condition when="paymentType" is={PAYMENTTYPE.cash.toString()}>
                                            <div className="form-group">
                                                <div className="column">
                                                    <Field name="receiveCash">
                                                        {({ input, meta }) => (
                                                            <div className="form-item">
                                                                <label htmlFor={input.name} className="label-with-input">받은금액</label>
                                                                <input {...input} type="text" id={input.name} placeholder="받음금액" onFocus={(e) => { e.target.select() }} data-invalid={meta.touched && meta.invalid} />
                                                            </div>
                                                        )}
                                                    </Field>

                                                    <Field name="changeCash">
                                                        {({ input, meta }) => (
                                                            <div className="form-item">
                                                                <label htmlFor={input.name} className="label-with-input">거스름돈</label>
                                                                <input {...input} type="text" id={input.name} placeholder="거스름돈" readOnly data-invalid={meta.touched && meta.invalid} />
                                                            </div>
                                                        )}
                                                    </Field>
                                                </div>

                                                <Field name="cashReceipts" type="checkbox">
                                                    {({ input, meta }) => (
                                                        <div className="switch-control">
                                                            <input {...input} type="checkbox" id={input.name} />
                                                            <label htmlFor={input.name}><span>현금영수증 여부</span></label>
                                                        </div>
                                                    )}
                                                </Field>
                                            </div>
                                        </Condition>
                                    </ConditionNot>

                                    <FieldArray name="takeout" component={SegmentedControlComponet} options={takeoutOptions} />
                                    <FieldArray name="userType" component={SegmentedControlComponet} options={userTypeOptions} />

                                    <div className="form-item">
                                        <Field name="description" component="textarea" placeholder="추가정보를 입력하세요." />
                                    </div>
                                </dd>
                            </dl>
                            <FormSpy onChange={(formState) => {
                                updateData(formState.values);
                            }} />



                            {/*  */}
                            <div className="order-submits">
                                <button type="button" className="button-normal" onClick={() => {history.goBack()}}><span className="btn-label">주문목록으로 돌아가기</span></button>
                                { values.id ? (
                                    <Mutation
                                        mutation={DELELTE_ORDER}
                                        variables={{
                                            id: values.id
                                        }}
                                        onCompleted={() => {
                                            toast.success("주문 삭제가 완료되었습니다. 목록페이지로 이동합니다.");
                                            history.push('/orders');
                                        }}
                                        refetchQueries={[{
                                            query: GET_ORDERS,
                                        }]}
                                    >
                                        {( deleteOrder, result ) => {
                                            const { data, loading, error, called } = result;
                                            return(
                                                <button type="button" className="button-submit" data-type="strong" disabled={submitting || loading} data-state={loading && "loading"}
                                                    onClick={() => {
                                                        $.confirm({
                                                            title: 'Warning!',
                                                            content: `정말 삭제하시겠습니까?`,
                                                            type: 'warning',
                                                            buttons: {
                                                                cancel: {
                                                                    text: 'CANCEL',
                                                                    btnClass: 'button-normal',
                                                                    action: function() {
                                                                    }
                                                                },
                                                                confirm: {
                                                                    text: 'OK',
                                                                    btnClass: 'button-submit strong',
                                                                    action: function() {
                                                                        deleteOrder();
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    }} 
                                                ><span className="btn-label">삭제</span></button>
                                            )
                                        }}
                                    </Mutation>
                                ) : (
                                    <></>
                                )}
                                <button type="submit" className="button-submit" disabled={submitting || invalid || loading} data-state={loading && "loading"}><span className="btn-label">{values.id ? "주문수정" : "주문등록"}</span></button>
                            </div>
                        </fieldset>
                    </form>
                </>
                )
            }}
        />
    );
}





class OrderSummary extends React.Component {
    state = {
        data: {
            orderDay: moment().format('YYYY-MM-DD'),
            dcRate: "0",
            paymentType: "0",
            cashReceipts: false,
            takeout: "false",
            userType: "0",
            originPrice: "0",
            dcPrice: "0",
            countTakeout: "0",
            dcTakeoutPrice: "0",
            countTumbler: "0",
            dcTumblerPrice: "0",
            totalPrice: "0",
            receiveCash: "0",
            changeCash: "0",
            cellPhone: "",
            description: "",
            createdAt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
            items: [],            
            updateCount: 0
        },
        id: this.props.match.params.id,
        loading: false
    }

    componentDidMount = () => {
        let _ = this;
        $('input[name="createdAt"]').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            timePicker: true,
            timePicker24Hour: true,
            showISOWeekNumbers: true,
            startDate: moment(new Date()),
            minDate: moment(new Date("2018-12-01")),
            maxDate: moment(new Date()),
            ranges: {
                'Today': [moment(), moment()],
            },
            locale: {
                format: 'YYYY-MM-DDTHH:mm:ssZ',
            }
        }, function(start) {
            _.handleDates(start);
        });

        this.handleGetOrder();
    }


    handleGetOrder = async() => {
        const { id } = this.state;
        if( !id ) return false;

        this.setState({
            ...this.state,
            loading: true
        });

        const result = await this.props.client.query({
            query: GET_ORDER,
            variables: {
                id: id
            },
        });

        const loadData = result.data.order;

        const convertDataItems = loadData.items.map((item) => {
            delete item['__typename']
            return item;
        });

        const convertData = {
            id: loadData.id,
            orderDay: loadData.orderDay,
            dcRate: String(loadData.dcRate),
            paymentType: String(loadData.paymentType),
            cashReceipts: loadData.cashReceipts,
            takeout: String(loadData.takeout),
            userType: String(loadData.userType),
            originPrice: String(loadData.originPrice),
            dcPrice: String(loadData.dcPrice),
            countTakeout: String(loadData.countTakeout),
            dcTakeoutPrice: String(loadData.dcTakeoutPrice),
            countTumbler: String(loadData.countTumbler),
            dcTumblerPrice: String(loadData.dcTumblerPrice),
            totalPrice: String(loadData.totalPrice),
            receiveCash: "0",
            changeCash: "0",
            cellPhone: loadData.cellPhone,
            description: loadData.description,
            createdAt: moment(new Date(parseInt(loadData.createdAt))).format('YYYY-MM-DDTHH:mm:ssZ'),
            items: convertDataItems,
            updateCount: 0
        }

        this.setState({
            ...this.state,
            loading: false,
            data: convertData
        });



        let _ = this;
        $('input[name="createdAt"]').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            timePicker: true,
            timePicker24Hour: true,
            showISOWeekNumbers: true,
            startDate: convertData.createdAt,
            minDate: moment(new Date("2018-12-01")),
            maxDate: moment(new Date()),
            ranges: {
                'Today': [moment(), moment()],
            },
            locale: {
                format: 'YYYY-MM-DDTHH:mm:ssZ',
            }
        }, function(start) {
            _.handleDates(start);
        });

    }


    handleDates = (start) => {
        this.setState({
            data: {
                ...this.state.data,
                createdAt: start.format()
            }
        });
    }

    componentWillReceiveProps = (nextProps) => {
        if( nextProps.selectedItem !== undefined ) {
            this.handleAddItem(nextProps.selectedItem);
            return true;
        }
    }

    handleAddItem = (product) => {
        let tempData = this.state.data;
        let tempItems = tempData.items;

        let offer = 0
        if( product.isIced !== undefined && product.isIced ) {
            offer = 1;
        } else if( product.isDessert !== undefined && product.isDessert ) {
            offer = 2;
        }

        const index = tempItems.findIndex((item) => (item.productID === product.id && item.offer === offer));
        const count = index < 0 ? 1 : parseInt(tempItems[index].count + 1, 10);

        let newItem = {
            categoryID: product.category.id,
            categoryOrder: product.category.order,
            productID: product.id,
            name: product.name,
            offer: offer,
            count: count,
            order: product.order,
            price: parseInt(product.price, 10)
        }

        if( count === 1 ) {
            tempItems.push(newItem);
        } else {
            tempItems[index] = newItem;
        }

        tempItems.sort((a, b) => {
            if(a.categoryOrder < b.categoryOrder) { return -1; }
            if(a.categoryOrder > b.categoryOrder) { return 1; }

            if(a.order < b.order) { return -1; }
            if(a.order > b.order) { return 1; }
        });

        tempData.items = tempItems;

        let originPrice = 0;
        if( tempItems.length > 0 ) {
            tempItems.forEach((item) => {
                originPrice = originPrice + (parseInt(item.price, 10) * parseInt(item.count, 10));
            });
            const dcRate = -Number(tempData.dcRate)/100;
            const dcPrice = originPrice * dcRate;
            const totalPrice = originPrice + dcPrice;

            this.setState({
                ...this.state,
                data: {
                    ...this.state.data,
                    updateCount: this.state.data.updateCount + 1,
                    originPrice: numberWithCommas(originPrice, false),
                    dcPrice: numberWithCommas(dcPrice, false),
                    totalPrice: numberWithCommas(totalPrice, false),
                    items: tempItems
                }
            });
        }
    }

    handleUpdateData = (values) => {
        let newValues = values;

        let originPrice = 0;
        let dcPrice = 0;
        let dcTakeoutPrice = 0;
        let dcTumblerPrice = 0;
        let totalPrice = 0;
        if( newValues.items.length > 0 ) {
            newValues.items.forEach((item) => {
                originPrice = originPrice + (parseInt(item.price, 10) * parseInt(item.count, 10));
            });
            const dcRate = -Number(newValues.dcRate)/100;
            dcPrice = originPrice * dcRate;
            totalPrice = originPrice + dcPrice;
        }
        dcTakeoutPrice = -Number(newValues.countTakeout)*500;
        dcTumblerPrice = -Number(newValues.countTumbler)*500;
        totalPrice = totalPrice + dcTakeoutPrice + dcTumblerPrice;

        let changeCash = 0;
        if( Number(newValues.paymentType) === PAYMENTTYPE.cash ) {
            changeCash = Number(newValues.receiveCash) - totalPrice;
            changeCash = changeCash < 0 ? 0 : changeCash;
        }

        newValues = {
            ...newValues,
            originPrice: numberWithCommas(originPrice, false),
            dcPrice: numberWithCommas(dcPrice, false),
            dcTakeoutPrice: numberWithCommas(dcTakeoutPrice, false),
            dcTumblerPrice: numberWithCommas(dcTumblerPrice, false),
            totalPrice: numberWithCommas(totalPrice, false),
            changeCash: numberWithCommas(changeCash, false)
        }

        this.setState({
            ...this.state,
            data: newValues
        });
    }

    handleResetData = () => {
        const resetValues = {
            ...this.state.data,
            dcRate: "0",
            paymentType: "0",
            cashReceipts: false,
            takeout: "false",
            userType: "0",
            originPrice: "0",
            dcPrice: "0",
            countTakeout: "0",
            dcTakeoutPrice: "0",
            countTumbler: "0",
            dcTumblerPrice: "0",
            totalPrice: "0",
            receiveCash: "0",
            changeCash: "0",
            cellPhone: undefined,
            description: "",
            createdAt: moment(new Date()).format('YYYY-MM-DDTHH:mm:ssZ'),
            items: [],            
            updateCount: 0
        }

        this.setState({
            ...this.state,
            data: resetValues
        });
    }

    render() {
        const { id, loading, data } = this.state;

        const addOrderSummaryContainer = (
            <Mutation
                mutation={ADD_ORDER}
                onCompleted={() => {
                    // this.props.history.push('/orders/');
                    toast.success("주문이 등록되었습니다. 추가로 등록하실 수 있습니다.");
                    this.handleResetData();
                }}
                refetchQueries={[{ query: GET_ORDERS }]}
            >
                {( addOrder, result ) => {
                    return(
                        <OrderForms {...this.props} initData={this.state.data} result={result} submitAction={addOrder} updateData={this.handleUpdateData} resetData={this.handleResetData}></OrderForms>
                    )
                }}
            </Mutation>
        );

        const editOrderSummaryContainer = () => {
            if( loading ) return <LoadingComponent />;

            return(
                <Mutation
                    mutation={UPDATE_ORDER}
                    onCompleted={() => {
                        toast.success("주문 수정이 완료되었습니다. 목록페이지로 이동합니다.");
                        this.props.history.goBack();
                    }}
                >
                    {( updateOrder, result ) => {
                        return(
                            <OrderForms {...this.props} initData={this.state.data} result={result} submitAction={updateOrder} updateData={this.handleUpdateData}></OrderForms>
                        );
                    }}
                </Mutation>
            )
        }

        return(
            <>
                {id ? editOrderSummaryContainer() : addOrderSummaryContainer}
            </>
        );
    }
}

export default withApollo(OrderSummary);
