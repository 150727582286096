import React from 'react';
import { Query, withApollo } from 'react-apollo';
import { ORDERS_BY_TIME } from './queries';
import { LoadingComponent } from '../components';


import { PAYMENTTYPE, groupedByKey } from '../constants';
import moment from 'moment';


/// amchart
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_dark from "@amcharts/amcharts4/themes/dark";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
am4core.useTheme(am4themes_dark);
am4core.useTheme(am4themes_animated);

class SaleChartByTime extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            date: props.date !== undefined ? props.date : moment().format("YYYY-MM-DD"),
            totalPrice: 0,
            sales: []
        }
    }

    componentDidMount = () => {
        this.getOrdersByTime();
    }

    componentWillUnmount = () => {
        if (this.chart) {
            this.chart.dispose();
        }
    }

    static getDerivedStateFromProps = (nextProps, prevState) => {
        const { date } = prevState;
        if( date !== nextProps.date ) {
            return {
                date: nextProps.date,
                loading: true
            }
        }
        return null;
    }

    componentDidUpdate = (prevProps, prevState) => {
        if( this.state.loading === true ) {
            this.getOrdersByTime();
        }
    }

    getOrdersByTime = async() => {
        const { date } = this.state;
        
        const results = await this.props.client.query({
            query: ORDERS_BY_TIME,
            variables: {
                date: date,
            },
            fetchPolicy: 'network-only'
        });

        this.setState({
            loading: false,
        });

        console.log( results.data.ordersByTime );
        this.handleGetOrdersByTime(results.data.ordersByTime);
    }



    convertChartData = (data) => {
        let totalPrice = 0;
        let chartData = data.map((order) => {
            totalPrice += order.price

            if( order.paymentType === PAYMENTTYPE.prepaid ) {
                totalPrice -= order.price;
            }
            return {
                ...order,
                hour: moment(order.createdAt, "x").format("HH")
            }
        })

        const groupedOrderHour = groupedByKey(chartData, item => item.hour);

        let result = [];
        let accumulatedPrice = 0;
        for( let i=10; i<24; i++ ) {
            const group = groupedOrderHour.get(i.toString());

            let price = 0;
            let hourCount = 0;

            if( group !== undefined ) {
                price = group[0].price;
                hourCount = group.length;

                if( group.length > 1 ) {
                    price = group.reduce((result, { paymentType, price }) => {
                        return paymentType !== PAYMENTTYPE.prepaid ? result + price : result
                    }, 0)
                }
            }

            accumulatedPrice += price;

            result.push({
                hour: new Date().setHours(i, 0, 0),
                accumulatedPrice,
                hourPrice: price,
                totalPrice,
                hourCount
            })
        }

        this.setState({
            totalPrice: totalPrice
        });

        return result;
    }


    handleGetOrdersByTime = ( ordersByTime ) => {
        const chartData = this.convertChartData(ordersByTime);
        this.setState({
            sales: ordersByTime,
            chartData
        });

        const { onComplete } = this.props;
        const { totalPrice } = this.state;

        if( onComplete !== undefined ) {
            onComplete( totalPrice );
        }

        this.makeChart();
    }


    makeChart = () => {
        const { chartData } = this.state;

        let chart = am4core.create("byTime-chartContainer", am4charts.XYChart);

        const data = chartData;

        chart.data = data;

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.baseInterval = {
            "timeUnit": "minute",
            "count": 1
        };

        var valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = false;
        valueAxis.min = 0;
        
        
        var series = chart.series.push(new am4charts.ColumnSeries());
        series.dataFields.dateX = "hour";
        series.dataFields.valueY = "hourPrice";
        series.sequencedInterpolation = true;
        series.name = "매출";
        series.tooltipText = "매출: {valueY} 원";
        series.columns.template.strokeWidth = 20;
        series.columns.template.column.cornerRadiusTopLeft = 2;
        series.columns.template.column.cornerRadiusTopRight = 2;
        series.columns.template.column.fillOpacity = 0.3;
        

        var accumulatedPriceSeries = chart.series.push(new am4charts.LineSeries());
        accumulatedPriceSeries.dataFields.dateX = "hour";
        accumulatedPriceSeries.name = "누적매출";
        accumulatedPriceSeries.dataFields.valueY = "accumulatedPrice";
        accumulatedPriceSeries.tooltipText = "누적매출 :{valueY.value}원";
        accumulatedPriceSeries.tooltip.getStrokeFromObject = true;
        accumulatedPriceSeries.tooltip.background.strokeWidth = 1;
        accumulatedPriceSeries.tooltip.getFillFromObject = false;
        accumulatedPriceSeries.fillOpacity = 0.1;
        accumulatedPriceSeries.strokeWidth = 1;
        accumulatedPriceSeries.stacked = false;
        

        
        var countAxis = chart.yAxes.push(new am4charts.ValueAxis());
        countAxis.renderer.grid.template.disabled = true;
        countAxis.title.text = "주문건수";
        countAxis.renderer.opposite = true;

        var countSeries = chart.series.push(new am4charts.LineSeries());
        countSeries.dataFields.valueY = "hourCount";
        countSeries.dataFields.dateX = "hour";
        countSeries.name = "주문건수"
        countSeries.yAxis = countAxis;
        countSeries.tooltipText = "주문건수: {valueY}";
        countSeries.strokeWidth = 3;

        var countBullet = countSeries.bullets.push(new am4charts.CircleBullet());
        countBullet.circle.fill = am4core.color("#fff");
        countBullet.circle.strokeWidth = 5;
        countBullet.circle.propertyFields.radius = "{hourCount}*5";

        var countState = countBullet.states.create("hover");
        countState.properties.scale = 1.5;

        
        chart.cursor = new am4charts.XYCursor();
        chart.cursor.fullWidthLineX = true;
        chart.cursor.xAxis = dateAxis;
        chart.cursor.lineX.strokeOpacity = 0;
        chart.cursor.lineX.fill = am4core.color("#000");
        chart.cursor.lineX.fillOpacity = 0.1;
        
        
        this.chart = chart;
    }

    render() {
        const { loading } = this.state;
        if( loading ) return <div className="overview-chart"><LoadingComponent /></div>

        return(
            <>
                <div className="overview-chart">
                    <div id="byTime-chartContainer" className="chart"></div>
                </div>
            </>
        );
    }
}

export default withApollo(SaleChartByTime);
