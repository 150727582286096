import React from 'react';
import FilterComponent from './FilterComponent';
import OrderItems from './OrderItems';
import OrderSummary from './OrderSummary';

import { OFFER } from '../../constants';


class AddOrderContainer extends React.Component {
    state = {
        filter: {
            offer: OFFER.hot,
            category: 'all'
        },
        selectedItem: undefined,
   }

    componentDidMount = () => { }


    handleFilterChange = (filter) => {
        this.setState({
            filter: filter,
            selectedItem: undefined
        });
    }

    handleItemSelect = (product) => {
        this.setState({
            selectedItem: product
        })
    }

    render() {
        const { filter, selectedItem } = this.state;

        return(
            <>
            <div className="layout-order-container">
                <OrderSummary {...this.props} selectedItem={selectedItem} />
            </div>

            <article className="layout-contents">
                <div className="layout-content-utils">
                    <div className="content-view">
                        <h1 className="page-title">주문하기</h1>
                    </div>

                    <div className="utils-view">
                        <FilterComponent onChange={this.handleFilterChange} />
                    </div>
                </div>


                <div className="layout-content-inner">
                    <OrderItems {...this.props} filters={filter} onItemSelect={this.handleItemSelect} />
                </div>
            </article>
            </>
        )
    }
}


export default AddOrderContainer;