export const AUTH_TOKEN = 'auth-token';


export const NUMBERREG = /^(0|[1-9]\d*)$/;
export const PHONENUMBERREG = /^(01[016789]{1}|02|0[3-9]{1}[0-9]{1})([0-9]{3,4})([0-9]{4})$/;
export const DAYOFWEEK = ['일', '월', '화', '수', '목', '금', '토'];




export const OFFER = {
    hot: 0,
    iced: 1,
    none: 2
};

export const getOffer = (value) => {
    switch(value) {
        case OFFER.hot: 
            return "뜨거운";
        case OFFER.iced:
            return "아이스";
        case OFFER.none:
            return "";
        default:
            return "뜨거운";
    }
};



export const PAYMENTTYPE = {
    credit: 0,
    cash: 1,
    prepaid: 2,
    none: 3
};

export const getPaymentType = (value) => {
    switch(value) {
        case PAYMENTTYPE.credit: 
            return "카드";
        case PAYMENTTYPE.cash:
            return "현금";
        case PAYMENTTYPE.prepaid:
            return "선결제 사용";
        case PAYMENTTYPE.none:
            return "없음";
        default:
            return "없음";
    }
};

export const getOptionsPaymentType = () => {
    let results = [];
    for( let obj in PAYMENTTYPE ) {
        results.push({
            label: getPaymentType(PAYMENTTYPE[obj]),
            value: String(PAYMENTTYPE[obj])
        });
    }

    return results;
}


export const USERTYPE = {
    woman: 0,
    man: 1,
    couple: 2,
    group: 3
};

export const getUserType = (value) => {
    switch(value) {
        case USERTYPE.woman: 
            return "여자";
        case USERTYPE.man:
            return "남자";
        case USERTYPE.couple:
            return "커플";
        case USERTYPE.group:
            return "단체";
        default:
            return "여자";
    }
};

export const getOptionsUserType = () => {
    let results = [];
    for( let obj in USERTYPE ) {
        results.push({
            label: getUserType(USERTYPE[obj]),
            value: String(USERTYPE[obj])
        });
    }

    return results;
}



export const TAKEOUT = {
    here: false,
    togo: true
};

export const getTakeout = (value) => {
    switch(value) {
        case TAKEOUT.here: 
            return "매장";
        case TAKEOUT.togo:
            return "테이크아웃";
        default:
            return "매장";
    }
};

export const getOptionsTakeout = () => {
    let results = [];
    for( let obj in TAKEOUT ) {
        results.push({
            label: getTakeout(TAKEOUT[obj]),
            value: String(TAKEOUT[obj])
        });
    }

    return results;
}


export const numberWithCommas = (x, unit) => {
    const stringUnit = unit === undefined ? "원" : "";
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + stringUnit;
}



export const groupedByKey = (list, keyGetter) => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return map;
}


export const isDev = process.env.NODE_ENV === "development";
