import React from 'react';
import { NavLink } from 'react-router-dom';
import { getToken, removeToken } from '../authroute';
import { isDev } from '../constants'


class GlobalMenu extends React.Component {
    handleLogout = () => {
        removeToken();
        this.props.history.push('/');
    }

    componentDidMount = () => {
        
    }

    render() {
        const auth = getToken();

        return(
            <header className="layout-globalmenu">
                <h2 className="hidden">pos.cafemodernb.com</h2>

                <div className="btns">
                    <NavLink exact to="/" className="btn-home">POS Cafemodern B</NavLink>
                    <button type="button" className="btn-toggle-menu" onClick={() => {document.querySelector('.layout-wrap').toggleAttribute("data-menu-expand")}}>toggle menu</button>
                </div>

                <div className="utils-view">
                    { auth
                        ? (
                            <button type="button" className="button-submit" data-type="strong" data-size="sm" onClick={this.handleLogout}>{isDev ? "DEV DB " : ""}logout</button>
                        )
                        : (
                            <NavLink exact to="/login" className="button-submit" data-state="inner">login</NavLink>
                        )
                    }
                </div>
            </header>
        );
    }
}

export default GlobalMenu;
